@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/backgrounds';
@import '../../../styles/animations';
.Feedback {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 12;
	.Feedback-content {
		position: absolute;		
		.Feedback-text {
			position: absolute;
			p {
				margin: 0;
				font-size: 0.8em;
				font-weight: bold;
				color: #12283E;
				text-align: center;
			}
		}
		.Feedback-audio {
			position: absolute;
			top: 4.5em;
			right: 1em;
		}
	}

	&.manager,
	&.master {
		.Feedback-content {
			left: calc(50% - 29em);
			bottom: 0;
			width: 27.11em;
			height: 17.5em;
			background-image: url('../../../assets/images/characters/manager-1.svg');
			background-position: top left;
			background-size: 15.5em auto;	
			.Feedback-text {
				width: 100%;
				height: 100%;
				top: 2.33em;
				right: 0;
				width: 16em;
				height: 9em;
				padding: 0.8em 4.65em 3em 2.56em;
				background-color: transparent;
				background-image: url('../../../assets/images/speech-bubble-feedback.svg');
				background-size: contain;
				@include flex('center', 'center');
			}
		}	
	}
}