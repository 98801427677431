@mixin button() {
	border-radius: 1.67em;
	@include box-shadow-inset-outset(
		0, 0.22em, 0, 0, rgba(#D1EDFF, 0.24),
		0, 0.11em, 0.11em, 0, rgba(#000000, 0.4)
	);
	@include flex('center', 'center');
	@include no-select();
}

@mixin button-blue() {
	@include button();
	color: white;
	background-image: linear-gradient(#ACDDFF, #2CA7FD, #0096FF);
}

@mixin button-green() {
	@include button();
	color: white;
	background-image: linear-gradient(#C2F2C2, #36DD36, #25D025);
}

@mixin button-red() {
	@include button();
	color: white;
	background-image: linear-gradient(#FFB9C8, #F55679, #F73963);
}


@mixin button-yellow() {
	border-radius: 1.67em;
	@include box-shadow-inset-outset(
		0, 0.11em, 0.17em, 0, #FFF1AF,
		0, 0.06em, 0.11em, 0, rgba(#000000, 0.42)
	);
	@include flex('center', 'center');
	@include no-select();
	color: #12283E;
	background-image: linear-gradient(#F8D949, #ECBD41);
}