@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/backgrounds';
@import '../../../styles/animations';
.Logo {
	display: block;
	width: 100%;
	height: 100%;
	background-image: url('../../../assets/images/logo-danish-crown.svg');
	background-size: contain;
	&.white {
		background-image: url('../../../assets/images/logo-danish-crown-white.svg');
	}
	&.cgl {
		color: transparent;
		overflow: hidden;
		@include no-select();
		background-image: url('../../../assets/images/logo-cgl.svg');
	}
}