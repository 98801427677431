@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/backgrounds';
@import '../../../styles/animations';
.Star {
	position: relative;
	.Star-glow {
		position: absolute;
		z-index: 3;
		top: 0.5em;
		width: 3em;
		height: 3em;
		background-image: radial-gradient(transparent 5%, white, transparent 75%);
		@include starGlow(0.5s);
	}
	.Star-image {
		position: relative;
		z-index: 4;
		@include flex('center', 'center');
		width: 1.75em;
		height: 100%;
		background-image: url('../../../assets/images/icons/icon-star.svg');
		background-size: contain;
		background-position: center center;

		&.filled {
			width: 3em;
			height: 3em;
			background-image: url('../../../assets/images/icons/icon-star-filled.svg');
		}
		&.animate {
			@include star(0.5s);
		}

		&.facilitator {
			width: 1.5em;
			height: 1.5em;
			&.filled {
				width: 1.5em;
				height: 1.5em;
			}

		}
	}
}
