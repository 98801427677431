@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/backgrounds';
@import '../../../styles/animations';
.AdminGameStatsPopup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#000000, 0.5);
	@include flex('center', 'center');

	.AdminGameStatsPopup-content {
		width: 80em;
		height: 35em;
		max-height: 100vh;
		background-color: white;
		border-radius: 0.83em;

		.AdminGameStatsPopup-header {
			position: relative;
			width: 100%;
			height: 4.25em;
			background-color: #f2482b;
			border-top-left-radius: 0.83em;
			border-top-right-radius: 0.83em;
			border-top: 0.25em solid white;
			border-right: 0.25em solid white;
			border-left: 0.25em solid white;
			.AdminGameStatsPopup-gameName {
				position: absolute;
				left: 2.56em;
				top: 0;
				bottom: 0;
				@include flex('flex-start', 'center');
				span {
					display: inline-block;
					color: #ffffff;
					font-size: 1.4em;
					font-weight: 600;
					margin-right: 1em;
				}
				input {
					width: 100%;
					color: #000000;
					font-size: 1.25em;
				}				
			}
			.AdminGameStatsPopup-closeBtn {
				position: absolute;
				top: 0;
				right: 0;
				width: 4.25em;
				height: 3.75em;
				background-image: url('../../../assets/images/icons/icon-cross-white.svg');
				background-size: 1.56em auto;
				cursor: pointer;
			}
		}

		.AdminGameStatsPopup-body {
			position: relative;
			height: calc(100% - 4.25em);
			padding: 1.5em 0;
			text-align: left;

			.AdminGameStatsPopup-module {
				position: relative;
				height: 2.25em;
				@include flex('center', 'center');
				.AdminGameStatsPopup-backBtn {
					position: absolute;
					left: 1.5em;
					width: 8em;
				}
				.AdminGameStatsPopup-moduleTitle {
					span {
						font-size: 1.5em;
						font-weight: bold;
					}
				}
			}

			.AdminGameStatsPopup-modules {
				.AdminGameStatsPopup-modulesCell {
					width: 18%;
					height: 100%;
					padding: 0 1.5em;
					@include flex('flex-start', 'center');
			
					&.name {
						width: 28%;
					}

				}
				.AdminGameStatsPopup-modulesHeader {
					width: 100%;
					height: 2.33em;
					color: white;
					background-color: #f2482b;
					@include flex('space-between', 'center');
					.AdminGameStatsPopup-modulesCell {
						span {
							display: inline-block;
							vertical-align: middle;
							position: relative;
							font-size: 1.39em;
							font-weight: 600;
			
						}
					}
				}
				.AdminGameStatsPopup-modulesBody {
					width: 100%;
					.AdminGameStatsPopup-modulesRow {
						width: 100%;
						height: 2.5em;
						color: #525252;
						@include flex('space-between', 'center');
						&:nth-child(odd) {
							background-color: rgba(#f2482b, 0.2);						
						}
						.AdminGameStatsPopup-modulesCell.remove {
							@include flex('flex-end', 'center');
						}
					}
				}
			}
		}
	}
}