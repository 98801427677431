@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/backgrounds';
@import '../../../../../styles/animations';
.SortDndContainer {
	position: relative;
	height: 100%;

	&.repetition-1-b-place-meat {
		background-color: white;
		border-radius: 0.78em;
		border: 0.28em solid #12283E;
		&.red {
			background-image: url('../../../../../assets/images/modules/tasks/sort/repetition-1-b-place-meat/container-red.svg');
			background-size: 7.63em auto;
			background-position: bottom 0.5em center;
		}
		&.green {
			background-image: url('../../../../../assets/images/modules/tasks/sort/repetition-1-b-place-meat/container-green.svg');
			background-size: 7.63em auto;
			background-position: bottom 0.5em center;
		}
		&.manager {
			background-image: url('../../../../../assets/images/modules/tasks/sort/repetition-1-b-place-meat/container-manager.svg');
			background-size: 6.3em auto;
			background-position: bottom -11.39em center;
		}
	}

	&.repetition-2-2a-lockers {
		
		width: 10.11em;
		height: 6.5em;
		background-color: white;
		border-radius: 0.28em;
		@include box-shadow-inset(0.06em, 0.06em, 0.06em, 0, rgba(#000000, 0.16));
	}
}