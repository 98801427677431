@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/backgrounds';
@import '../../../../../styles/animations';
.SortDndItem {
	position: relative;
	display: inline-block;
	cursor: move;
	span {
		color: white;
		font-size: 0.75em;
		font-weight: 700;
	}
	&.disabled {
		cursor: not-allowed;
	}
	&.completed,
	&.animateCorrect,
	&.animateWrong {
		&::after {
			content: '';
			position: absolute;
			bottom: 1em;
			right: 1em;
			width: 1.5em;
			height: 1.5em;
			background-image: url('../../../../../assets/images/icons/icon-corrrect.svg');
			background-size: 1.4em auto;
			background-position: center center;
		}
	}
	&.animateCorrect {
		@include bounce();
	}
	&.animateWrong {
		@include wobble();
		&::after {
			background-image: url('../../../../../assets/images/icons/icon-wrong.svg');
		}
	}

	&.repetition-1-b-place-meat {
		width: 10.67em;
		height: 10.67em;
		&.item-1 {
			background-image: url('../../../../../assets/images/modules/tasks/sort/repetition-1-b-place-meat/item-1.svg');
		}
		&.item-2 {
			background-image: url('../../../../../assets/images/modules/tasks/sort/repetition-1-b-place-meat/item-2.svg');
		}
		&.item-3 {
			background-image: url('../../../../../assets/images/modules/tasks/sort/repetition-1-b-place-meat/item-3.svg');
		}
		&.item-4 {
			background-image: url('../../../../../assets/images/modules/tasks/sort/repetition-1-b-place-meat/item-4.svg');
		}

		&.placed {
			width: 4.3em;
			height: 4.3em;
			margin: 0.22em;
		}
		
	}

	&.repetition-2-2a-lockers {
		display: inline-block;
		vertical-align: top;
		margin-right: 1.25em;
		margin-bottom: 1.25em;
		width: 6.11em;
		height: 6.11em;
		background-color: white;
		background-size: auto 80%;
		border-radius: 0.28em;
		
		@include box-shadow(0, 0.17em, 0.33em, 0, rgba(#000000, 0.16));
		&:nth-of-type(4),
		&:nth-of-type(8) {
			margin: 0;
		}

		&.item-1 {
			background-image: url('../../../../../assets/images/modules/tasks/sort/repetition-2-2a-lockers/item-1.svg');
			background-size: 80% auto;
		}
		&.item-2 {
			background-image: url('../../../../../assets/images/modules/tasks/sort/repetition-2-2a-lockers/item-2.svg');
			background-size: 80% auto;
		}
		&.item-3 {
			background-image: url('../../../../../assets/images/modules/tasks/sort/repetition-2-2a-lockers/item-3.svg');
		}
		&.item-4 {
			background-image: url('../../../../../assets/images/modules/tasks/sort/repetition-2-2a-lockers/item-4.svg');
			background-size: 80% auto;
		}
		&.item-5 {
			background-image: url('../../../../../assets/images/modules/tasks/sort/repetition-2-2a-lockers/item-5.svg');
			background-size: 80% auto;
		}
		&.item-6 {
			background-image: url('../../../../../assets/images/modules/tasks/sort/repetition-2-2a-lockers/item-6.svg');
			background-size: 80% auto;
		}

		&.placed {
			width: 4.3em;
			height: 4.3em;
			margin: 0.22em;
		}
	}
}