@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/backgrounds';
@import '../../../../../styles/animations';
.Sort {
	position: relative;
	height: 100%;
	padding-top: 5.6em;
	padding-bottom: 1em;
	overflow: hidden;

	.Sort-content {
		position: relative;
		width: 36.5em;
		
		// overflow: auto;
		// scroll-behavior: smooth;
		// -webkit-overflow-scrolling: touch;
		// @include hide-scrollbar();

		.Sort-instructions {
			position: relative;
			background-color: rgba(white, 0.8);
			border-radius: 1.11em;
			border: 0.17em solid #12283E;
			padding: 1em 4em 1em 1em;
			margin: 0 auto 1em auto;
			text-align: left;
			@include box-shadow(0, 0.17em, 0.33em, 0, #12283E);
			.Sort-audio {
				position: absolute;
				top: calc(50% - 1.36em);
				right: 1em;
			}
		}

		.Sort-itemsAndCategories {
			position: relative;
			background-color: rgba(white, 0.8);
			border-radius: 1.11em;
			border: 0.17em solid #12283E;
			padding: 2.22em 4.44em 1em 2.22em;
			margin: 0 auto;
			@include box-shadow(0, 0.17em, 0.33em, 0, #12283E);
			.Sort-items {
				position: relative;
			}
			.Sort-categories {
				position: relative;
				.Sort-category {
					.Sort-categoryTitle {
						@include flex('center', 'center');
						span {
							font-size: 0.78em;
							font-weight: 700;
						}
					}
				}
			}
		}

		
		

		.Sort-scrollDown {
			position: fixed;
			bottom: 0.1em;
			left: calc(50% - 1.375em);
			width: 2.75em;
			height: 2.75em;
			// background-image: url('../../../../assets/images/icons/icon-down.svg');
			cursor: pointer;
		}
	}

	&.repetition-1-b-place-meat {
		@include flex('center', 'center');

		.Sort-content {
			width: 51.22em;
			background-color: rgba(white, 0.8);
			border-radius: 1.11em;
			border: 0.17em solid #12283E;
			padding: 1em 2em 2em 2em;
			.Sort-instructionsAndItems {
				margin-bottom: 1.5em;
				.Sort-instructions {
					p {
						color: #12283E;
						font-size: 1.06em;
						font-weight: bold;
					}
				}
			}
			.Sort-items {
				width: 100%;
				height: 10.67em;
				@include flex('space-between', 'center');
			}
			.Sort-categories {
				width: 100%;
				height: 14.89em;
				@include flex('center', 'center');
				.Sort-category {
					width: 10.89em;
					height: 100%;
					margin: 0 0.75em;
				}
			}
			
		}
	}

	&.repetition-2-2a-lockers {
		@include flex('center', 'center');

		.Sort-content {
			
			.Sort-instructions {
					p {
						color: #12283E;
						font-size: 1.06em;
						font-weight: bold;
						margin: 0;
					}
				}
			
			.Sort-items {
				width: 100%;
				height: 100%;
				text-align: left;
				
				border-bottom: 0.17em solid #12283E;
				
			}
			.Sort-categories {
				width: 100%;
				padding-top: 1em;
				@include flex('space-between', 'center');
			}
			
		}
	}
}