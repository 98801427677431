@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/backgrounds';
@import '../../../styles/animations';
.ModulesOverview {
	position: relative;
	height: 100%;
	background-color: gainsboro;
	overflow: auto;
	@include flex('center', 'center');

	.ModulesOverview-logoCGL {
		position: absolute;
		top: 1em;
		right: 0.5em;
		width: 6.11em;
		height: 2em;
	}

	.ModulesOverview-resetBtn {
		position: absolute;
		top: 1em;
		left: 0.5em;
	}

	.ModulesOverview-content {
		position: relative;
		width: 27.27em;
		border-radius: 1.11em;
		background-color: rgba(white, 0.9);
		padding: 1.67em;
		border: 0.17em solid #12283E;
		@include box-shadow(0, 0.167em, 0.33em, 0, #12283E);
		.ModulesOverview-title {
			span {
				font-size: 1.67em;
				color: #12283E;
				font-weight: bold;
			}	
		}
		.ModulesOverview-employeeNumber {
			margin: 1em 0 1.5em 0;
			span {
				font-size: 1.11em;
				color: #6E8CA0;
			}
		}
		.ModulesOverview-modules {
			width: 18em;
			margin: auto;
			.ModulesOverview-module {
				position: relative;
				width: 100%;
				height: 3em;
				border-radius: 1.78em;
				margin-bottom: 0.5em;
				background-color: #F8D949;
				@include flex('center', 'center');
				@include box-shadow-inset-outset(
					0, 0.22em, 0, 0, rgba(#D1EDFF, 0.24),
					0.11em, 0.11em, 0.11em, 0, rgba(black, 0.4)
				);
				cursor:pointer;
				span {
					font-size: 1.06em;
					font-weight: bold;
					color: #12283E;
					text-transform: uppercase;
				}
				&.production,
				&.production-2,
				&.repetition-1-b,
				&.repetition-2-1b {
					margin-bottom: 1.89em;
				}
				&.completed {
					background-color: #C2F2C2;
					&::after {
						content: '';
						position: absolute;
						right: 0.89em;
						top: 0;
						width: 1.2em;
						height: 100%;
						background-image: url('../../../assets/images/icons/icon-checkmark.svg');
						background-size: contain;
					}
				}
				&.locked {
					background-color: #C2D1DC;
					cursor: not-allowed;
					@include opacity(0.63);
					span {
						color: white;
					}
				}
				&.disabled {
					&::after {
						content: '';
						position: absolute;
						right: 0.5em;
						top: 0;
						width: 1em;
						height: 100%;
						background-image: url('../../../assets/images/icons/icon-lock.svg');
						background-size: contain;
					}
				}
			}
		}
	}

	.ModulesOverview-buttons {
		position: absolute;
		bottom: 1em;
		right: 1em;
		@include flex('flex-end', 'center');
		.ModulesOverview-logoutBtn {
			margin-left: 0.5em;
		}
	}
}