@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/backgrounds';
@import '../../../../../styles/animations';
.OrderDndItem {
	position: relative;
	width: 17em;
	height: 3em;
	text-align: center;
	padding: 0 1em 0 3.5em;
	cursor: move;
	@include button-blue();

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 3.5em;
		height: 3em;
		background-size: contain;
		background-image: url('../../../../../assets/images/icons/icon-drag.svg');
		background-size: 2em auto;
	}
	span {
		font-size: 0.95em;
		font-weight: bold;
	}
	&.disabled {
		cursor: not-allowed;
		background-image: none;
	}	
	&.correct {
		@include button-green();
	}
	&.wrong {
		@include button-red();
	}

	.OrderDndItem-audio {
		position: absolute;
		right: 0.5em;
		top: calc(50% - 1em);
	}

}