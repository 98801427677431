@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/buttons';
@import '../../styles/backgrounds';
@import '../../styles/animations';
.Admin {
	position: relative;
	min-height: 100%;
	font-weight: 400;
	background-color: $white;
	overflow: auto;
	font-size: calc(18 * ((100vw / 1920)));
	.Admin-header {
		width: 100%;
		height: 12em;
	}
	.Admin-content {
		position: absolute;
		width: 100%;
		top: 12em;
		bottom: 0;
		padding: 1.5em 2em;
		overflow: auto;
		@include hide-scrollbar();
		.Admin-addGameBtn,
		.Admin-movePlayersBtn,
		.Admin-changePlayerIdBtn,
		.Admin-viewStatsBtn,
		.Admin-downloadPlayerProgressBtn {
			width: 10.83em;
			height: 2.67em;
			margin-bottom: 0.5em;
		}
		.Admin-movePlayersBtn {
			position: absolute;
			top: 1.5em;
			right: 46em;
			width: 14em;
		}
		.Admin-changePlayerIdBtn {
			position: absolute;
			top: 1.5em;
			right: 31.25em;
			width: 14em;
		}
		.Admin-downloadPlayerProgressBtn {
			position: absolute;
			width: 17em;
			right: 13.5em;
			top: 1.5em;
		}
		.Admin-viewStatsBtn {
			position: absolute;
			right: 2em;
			top: 1.5em;
		}
	}

	.Admin-popupOverlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(#000000, 0.5);
		@include flex('center', 'center');
	}
}