@font-face {
  font-family: "Danish Crown Sans";
  src: 	url("../assets/fonts/danish-crown-sans-regular.woff") format("woff"), 
				url("../assets/fonts/danish-crown-sans-regular.woff2") format("woff2"), 
				url("../assets/fonts/danish-crown-sans-regular.eot") format("eot");
	font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: "Danish Crown Sans";
  src: 	url("../assets/fonts/danish-crown-sans-bold.woff") format("woff"), 
				url("../assets/fonts/danish-crown-sans-bold.woff2") format("woff2"), 
				url("../assets/fonts/danish-crown-sans-bold.eot") format("eot");
	font-weight: bold;
	font-style: normal;
}

@font-face {
  font-family: "Danish Crown Sans";
  src: 	url("../assets/fonts/danish-crown-sans-italic.woff") format("woff"), 
				url("../assets/fonts/danish-crown-sans-italic.woff2") format("woff2"), 
				url("../assets/fonts/danish-crown-sans-italic.eot") format("eot");
	font-weight: normal;
	font-style: italic;
}

@font-face {
  font-family: "Danish Crown Sans";
  src: 	url("../assets/fonts/danish-crown-sans-bold-italic.woff") format("woff"), 
				url("../assets/fonts/danish-crown-sans-bold-italic.woff2") format("woff2"), 
				url("../assets/fonts/danish-crown-sans-bold-italic.eot") format("eot");
	font-weight: bold;
	font-style: italic;
}