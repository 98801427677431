@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/backgrounds';
@import '../../../../../styles/animations';
.SortDndDragLayer {
	.SortDndDragLayer-item {
		position: relative;
		display: inline-block;
		span {
			color: white;
			font-size: 0.75em;
			font-weight: 700;
		}

		&.repetition-1-b-place-meat {
			width: 10.67em;
			height: 10.67em;
			&.item-1 {
				background-image: url('../../../../../assets/images/modules/tasks/sort/repetition-1-b-place-meat/item-1.svg');
			}
			&.item-2 {
				background-image: url('../../../../../assets/images/modules/tasks/sort/repetition-1-b-place-meat/item-2.svg');
			}
			&.item-3 {
				background-image: url('../../../../../assets/images/modules/tasks/sort/repetition-1-b-place-meat/item-3.svg');
			}
			&.item-4 {
				background-image: url('../../../../../assets/images/modules/tasks/sort/repetition-1-b-place-meat/item-4.svg');
			}
		}

		&.repetition-2-2a-lockers {
			width: 6.11em;
			height: 6.11em;
			
			&.item-1 {
				background-image: url('../../../../../assets/images/modules/tasks/sort/repetition-2-2a-lockers/item-1.svg');
			}
			&.item-2 {
				background-image: url('../../../../../assets/images/modules/tasks/sort/repetition-2-2a-lockers/item-2.svg');
			}
			&.item-3 {
				background-image: url('../../../../../assets/images/modules/tasks/sort/repetition-2-2a-lockers/item-3.svg');
			}
			&.item-4 {
				background-image: url('../../../../../assets/images/modules/tasks/sort/repetition-2-2a-lockers/item-4.svg');
			}
			&.item-5 {
				background-image: url('../../../../../assets/images/modules/tasks/sort/repetition-2-2a-lockers/item-5.svg');
			}
			&.item-6 {
				background-image: url('../../../../../assets/images/modules/tasks/sort/repetition-2-2a-lockers/item-6.svg');
			}
		}
	}
}