@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/backgrounds';
@import '../../../../../styles/animations';
.Image {
	position: relative;
	height: 100%;
	overflow: hidden;
	
	&.personal-hygiene-lockers {
		background-color: white;
		background-image:
			url('../../../../../assets/images/modules/tasks/image/personal-hygiene-lockers.svg'),
			url('../../../../../assets/images/backgrounds/dressing-room-floor-grid.svg'),
			linear-gradient(#A2B5C4, #D9E6EE),
			url('../../../../../assets/images/backgrounds/slaughterhouse-wall-grid.svg'),
			radial-gradient(rgba(white, 0), rgba(#AFBFCB, .29) 10%, rgba(#ACDDFF, 0.62)),
			linear-gradient(#788999, rgba(#C5D1DB, 0.09))
		;
		background-size: 
			auto 36.1em,
			auto 10.52em,
			100% 10.52em,
			100% auto,
			122% calc(100% - 4em),
			auto 9em;
		
		background-position: 
			bottom 2.13em left calc(50% - 3.5em),
			bottom center,
			bottom center,
			center center,
			top -4em center,
			top center;
		;
		background-repeat:
			no-repeat,
			repeat-x,
			no-repeat,
			repeat-y,
			no-repeat,
			no-repeat;

		&::after {
			// Covers first background image
			content: '';
			position: absolute;
			bottom: 2.13em;
			left: 0;
			width: 100%;
			height: 36.1em;
			background-image: url('../../../../../assets/images/text/private-en.svg');
			background-size: auto 0.8em;
			background-position: top 1em left calc(50% + 9.6em);
			pointer-events: none;
		}
		&.da::after,
		&.de::after {
			background-image: url('../../../../../assets/images/text/private-da.svg');
			background-position: top 1em left calc(50% + 9.4em);
		}
		&.de::after {
			background-image: url('../../../../../assets/images/text/private-de.svg');
		}
		&.pl::after {
			background-image: url('../../../../../assets/images/text/private-pl.svg');
		}
	}
}