@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/backgrounds';
@import '../../../../../styles/animations';
.Results {
	@include flex('center', 'center');
	position: relative;
	height: 100%;
	padding: 2em 1em;
	overflow: auto;

	.Results-backgroundWrapper {
		.Results-background {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			@include background-danish-crown();

			&.dressing-room {
				@include background-dressing-room();
			}
			&.dressing-room2 {
				@include background-dressing-room2('da');
				&.en {@include background-dressing-room2('en');}
				&.pl {@include background-dressing-room2('pl');}
				&.de {@include background-dressing-room2('de');}
			}
			&.slaughterhouse{
				@include background-slaughterhouse();
			}
		}

		.Results-backgroundOverlay {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: rgba(#0E3856, 0.6);
		}
	}

	.Results-content {
		position: relative;
		width: 22.44em;
		background-color: rgba($white, 0.9);
		border: 0.2em solid #12283E;
		border-radius: 1.1em;
		padding: 2.25em 0 1em 0;

		.Results-title {
			position: relative;
			font-size: 1.5em;
			font-weight: 700;
			color: #E8B341;
			margin: 0;
			text-align: center;
			margin-bottom: 0.5em;
		}

		.Results-result {
			width: 100%;
			position: relative;
			margin-bottom: 1.5em;
			.Results-resultLabel {
				text-align: center;
				font-size: 0.89em;
				font-weight: bold;
				color: #12283E;
			}
			.Results-resultValue {
				margin: auto;
				width: 9.5em;
				height: 2.25em;
				border-radius: 1em;
				color: #E8B341;
				background-color: #082940;
				margin-top: 0.5em;
				@include flex('center', 'center');
				span {
					font-size: 1.33em;
					font-weight: 700;
				}
			}
		}

		.Results-points {
			margin-top: 1em;
			font-size: 1.25em;
			font-weight: 700;
			color: white;
			text-align: center;
		}

		.Results-errors {
			margin-top: 1em;
			text-align: center;
			color: white;
		}

		.Results-buttons {
			@include flex('flex-start', 'center', 'column');
			.Results-backBtn {
				margin-bottom: 0.5em;
			}
			.Results-backBtn,
			.Results-retryBtn {
				min-width: 10em;
				.Button {
					padding: 0 0.5em;
				}
			}
			
		}
	}
}

@media (orientation: portrait) and (min-aspect-ratio: 375/667) { // wider portrait than design, scale font size after hide
	.Results {
    font-size: calc(18 * ((100vh / 667)));
  }
}