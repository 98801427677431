@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/buttons';
@import '../../styles/backgrounds';
@import '../../styles/animations';
.PlayerLobby {
	position: relative;
	height: 100%;
	background-image: linear-gradient(#10779E, #ADE9FF);
	background-size: 100% 100%;
	padding: 5em 1em;
	.PlayerLobby-logoutBtn {
		position: absolute;
		top: 0.833em;
		width: 5.6em;
		left: calc(50% - 2.8em);
	}
	.PlayerLobby-title {
		color: white;
		font-size: 1.25em;
		text-align: center;
		margin-bottom: 2em;
	}
	.PlayerLobby-text {
		margin: auto;
		width: 25em;
		color: white;
		text-align: left;
	}
	.PlayerLobby-buttons {
		margin-top: 2em;
		.PlayerLobby-button {
			margin: 1.5em auto;
			width: 18em;
		}
	}

	.PlayerLobby-popup {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(#092335, 0.6);
		z-index: 13;
		@include flex('center', 'center');
	
		.PlayerLobby-popupContent {
			position: relative;
			width: 18em;
			color: #11466B;
			background-color: white;
			padding: 1em 1em 1.5em 1em;
			border-radius: 1em;
			text-align: center;
			@include box-shadow(0, 0, 0.66em, 0, rgba(#000000, 0.2));
		}
	}
}