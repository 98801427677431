@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/backgrounds';
@import '../../../styles/animations';
.AdminRenamePlayerPopup {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#092335, 0.6);
	z-index: 13;
	@include flex('center', 'center');

	.AdminRenamePlayerPopup-content {
		position: relative;
		width: 30em;
		min-height: 10em;
		color: #11466B;
		background-color: white;
		border-radius: 1em;
		text-align: center;
		@include box-shadow(0, 0, 0.66em, 0, rgba(#000000, 0.2));

		.AdminRenamePlayerPopup-title {
			background-color: #f2482b;
			border-top-left-radius: 1em;
			border-top-right-radius: 1em;
			height: 3em;
			padding: 0 1em;
			@include flex('flex-start', 'center');
			span {
				color: white;
				font-size: 1.56em;
				font-weight: 700;
	
			}
			
		}
		.AdminRenamePlayerPopup-text {
			text-align: left;
			padding: 1em;
			
			.AdminRenamePlayerPopup-currentEmployeeId {
				margin-bottom: 1em;
				span {
					display: inline-block;
					font-size: 1em;
					font-weight: 500;
					&:first-of-type {
						font-weight: 700;
						width: 15em;
					}
				}
			}
			.AdminRenamePlayerPopup-input {
				margin-bottom: 1em;
				span {
					display: block;
					font-weight: 700;
				}
				input {
					
					display: inline-block;
					margin-top: 0.5em;
					width: 13em;
					height: 2em;
					padding: 0 0.5em;
					font-size: 1em;
					border: 1px solid #525252;
					border-radius: 0.5em;
				}
			}
		}
		.AdminRenamePlayerPopup-feedback {
			height: 2em;
			margin-bottom: 0.5em;
			span {
				font-size: 0.8em;
				color: black;
			}
			&.error span {color: #C13B3B;}
		}

		.AdminRenamePlayerPopup-confirmBtn {
			width: 10em;
		}
	}
}
