@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/backgrounds';
@import '../../../../../styles/animations';
.TaskIntro {
	position: relative;
	width: 100%;		
	padding-right: 5.5em;
	text-align: left;

	.TaskIntro-text {
		color: #12283E;
		padding-bottom: 0.5em;
		p {
			font-size: 1.06em;
			font-weight: bold;
			margin-top: 0;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
		span {
			display: inline-block;
			margin-top: 1em;
			font-size: 1.06em;
			font-weight: bold;
		}
	}

	.TaskIntro-image {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background-size: 5.22em auto;
		background-position: bottom right;
	}

	.TaskIntro-audio {
		position: absolute;
		top: calc(50% - 1.36em);
		right: 1em;
	}
}
