@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/backgrounds';
@import '../../../../../styles/animations';
.OrderDndPreview {	
	.OrderDndPreview-item {
		position: relative;
		width: 17em;
		height: 3em;
		padding: 0 1em 0 3.5em;
		@include button-blue();
		@include box-shadow(0.3em, 0.3em, 0.3em, 0, rgba(#000000, 0.4));
		span {
			font-size: 0.95em;
			font-weight: bold;
		}		
		&::after {
			content: '';
			position: absolute;
			height: 100%;
			width: 3.5em;
			left: 0;
			top: 0;
			cursor: move;
			background-image: url('../../../../../assets/images/icons/icon-drag.svg');
			background-size: 2em auto;
		}
	}
}