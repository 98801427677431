@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/backgrounds';
@import '../../../styles/animations';
.AdminGames {
	position: relative;
	width: 100%;

	.AdminGames-cell {
		width: 9%;
		height: 100%;
		overflow: hidden;
		@include flex('flex-start', 'center');

		&.name {
			width: 18%;
			padding-left: 1.5em;
		}
		&.facilitators {
			width: 10%;
		}
		&.gameUrl {
			width: 25%;
		}
		&.gameInfo,
		&.gameStats {
			width: 10%;
		}

		.AdminGames-viewInfoBtn,
		.AdminGames-viewStatsBtn {
			width: 95%;
			height: 2em;		
		}
	}
	.AdminGames-header {
		width: 100%;
		height: 2.33em;
		color: white;
		background-color: #f2482b;
		@include flex('space-between', 'center');
		.AdminGames-cell {
			span {
				display: inline-block;
				vertical-align: middle;
				position: relative;
				font-size: 1.39em;
				font-weight: 600;

			}
			&.name,
			&.created {
				cursor: pointer;
			}
			&.sortedBy {
				span::after {
					content: '';
					display: inline-block;
					vertical-align: middle;
					width: 1em;
					height: 1em;
					background-size: 0.6em auto;
					background-image: url('../../../assets/images/icons/icon-sort-desc.svg');
				}
				&.ASC span::after {background-image: url('../../../assets/images/icons/icon-sort-asc.svg');}
			}
		}
	}
	.AdminGames-body {
		width: 100%;
		.AdminGames-row {
			width: 100%;
			height: 2.5em;
			color: #525252;
			@include flex('space-between', 'center');
			&:nth-child(odd) {
				background-color: rgba(#f2482b, 0.2);
			}
			.AdminGames-cell {
				&.gameStats {
					cursor: default;
				} 
			}
		}
	}
}