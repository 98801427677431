@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/buttons';
@import '../../styles/backgrounds';
@import '../../styles/animations';
.ChooseLanguage {
	height: 100%;
	width: 100%;
	@include flex('center', 'center');

	.ChooseLanguage-logoCGL {
		position: absolute;
		top: 1em;
		right: 0.5em;
		width: 6.11em;
		height: 2em;
	}

	.ChooseLanguage-content {
		position: relative;
		margin-bottom: 11em;
		.ChooseLanguage-title {
			@include text-shadow(0.11em, 0.11em, 0.11em, #12283E);
			span {
				color: #ffffff;
				font-size: 2.22em;
				font-weight: bold;
				
			}
		}
		.ChooseLanguage-languages {
			position: relative;
			margin-top: 1.5em;
			.ChooseLanguage-language {
				display: inline-block;
				vertical-align: top;
				width: 8.38em;
				height: 4.86em;
				margin: 0 0.35em;
				cursor: pointer;
				background-size: cover;
				&.da {
					background-image: url('../../assets/images/languages/da.svg');
				}
				&.en {
					background-image: url('../../assets/images/languages/en.svg');
				}
				&.de {
					background-image: url('../../assets/images/languages/de.svg');
				}
				&.pl {
					background-image: url('../../assets/images/languages/pl.svg');
				}
			}
		}
	}

	.ChooseLanguage-logoutBtn {
		position: absolute;
		bottom: 1em;
		left: 1em;
	}
}