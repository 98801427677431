@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/backgrounds';
@import '../../../styles/animations';
.DcBackground {
	position: absolute;
	width: 100%;
	height: auto;
	min-height: 100%;
	pointer-events: none;
	.DcBackground-background {
		position: absolute;
		height: auto;
		min-height: 100%;
		width: 100%;
		bottom: 0;
		left: 0;

		#cloud-1 {
			@include translatePercentage(22s, -6s, 180%);
		}
		#cloud-2 {
			@include translatePercentage(30s, -16s, 155%);
		}
		#cloud-3 {
			@include translatePercentage(25s, -20s, 155%);
		}

		&.scenario1 {
			#pigs-1 {
				@include translatePercentage(30s, -15s, 47%);
			}
			#pigs-2 {
				@include translatePercentage(30s, 0s, 47%);
			}
		}

		&.scenario2 {
			#boxes-1 {
				@include translatePercentage(30s, -15s, 30.75%);
			}
			#boxes-2 {
				@include translatePercentage(30s, 0s, 30.75%);
			}
		}
	}
}