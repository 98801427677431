@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/backgrounds';
@import '../../../../../styles/animations';
.SpotErrors {
	position: relative;
	height: 100%;
	overflow: auto;
	background-color: white;
	&.shake {
		@include shake();
	}
		
	
	.SpotErrors-text {
		position: absolute;
		top: 7em;
		left: calc(50% - 24.5em);
		width: 17.78em;
		border-radius: 1.11em;
		background-color: rgba(white, 0.9);
		border: 0.17em solid #12283E;
		padding: 1.27em 4.83em 1.27em 1.94em;
		@include box-shadow(0, 0.17em, 0.33em, 0, #12283E);
		p {
			color: #12283E;
			font-size: 1.05em;
			font-weight: bold;
			text-align: left;
		}
		.SpotErrors-audio {
			position: absolute;
			top: calc(50% - 1.36em);
			right: 1em;
		}
	}

	.SpotErrors-errors {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;

		.SpotErrors-error {
			position: absolute;
			width: 5em;
			height: 5em;
			border-radius: 100%;
			border: 0 solid #11466B;
			&.spotted {
				background-image: url('../../../../../assets/images/icons/icon-corrrect.svg');
				background-position: center center;
				background-size: 2em auto;
				@include no-box-shadow();
				@include scale(0);
				@include popInImage(0.5s);
			}
		}
	}

	.SpotErrors-confirmBtn {
		position: fixed;
		right: calc(50% - 0.5 * 8.78em);
		bottom: 1.44em;
		height: 2.67em;
		width: 8.78em;
		cursor: pointer;
		@include button-yellow();
		span {
			font-size: 1.22em;
			font-weight: bold;
		}
	}

	&.completed {
		.SpotErrors-error.spotted {
			background-size: 2em auto;
			@include noAnimation();
		}
		.SpotErrors-error:not(.spotted) {
			@include box-shadow(0, 0, 0em, 0.22em, #fff);
			@include popIn(0.5s);
		}
	}

	&.personal-hygiene-dressing-room {
		background-color: white;
		background-image:
			url('../../../../../assets/images/modules/tasks/spot-errors/personal-hygiene-dressing-room/background.svg'),
			url('../../../../../assets/images/backgrounds/dressing-room-floor-grid.svg'),
			linear-gradient(#A2B5C4, #D9E6EE),
			url('../../../../../assets/images/backgrounds/slaughterhouse-wall-grid.svg'),
			radial-gradient(rgba(white, 0), rgba(#AFBFCB, .29) 10%, rgba(#ACDDFF, 0.62)),
			linear-gradient(#788999, rgba(#C5D1DB, 0.09));

		background-size: 
			auto 35.25em,
			auto 10.52em,
			100% 10.52em,
			100% auto,
			122% calc(100% - 4em),
			auto 9em;
		
		background-position: 
			bottom 2.1em left calc(50% - 3.5em),
			bottom center,
			bottom center,
			center center,
			top -4em center,
			top center;
		;
		background-repeat:
			no-repeat,
			repeat-x,
			no-repeat,
			repeat-y,
			no-repeat,
			no-repeat;

		.SpotErrors-errors { 
			.SpotErrors-error {
				&.gloves {
					bottom: 28em;
					left: calc(50% + 8.75em);
					width: 3.5em;
					height: 3em;
				}
				&.knife {
					bottom: 31em;
					left: calc(50% + 7.75em);
					height: 2.5em;
				}	
				&.work-shoes {
					bottom: 5.6em;
					left: calc(50% + 8.7em);
					width: 4em;
					height: 4em;
				}
				&.hair-net {
					bottom: 27.75em;
					left: calc(50% + 5.25em);
					width: 3.25em;
					height: 3.25em;
				}
				&.sandwich {
					bottom: 5.75em;
					left: calc(50% - 23.25em);

				}
				&.soda {
					bottom: 1em;
					left: calc(50% - 17.6em);
					width: 6em;
					height: 4em;
				}
			}
		}
	}

	&.production-slaughter-hall {
		background-color: white;
		background-image:
			url('../../../../../assets/images/modules/tasks/spot-errors/production-slaughter-hall/background.svg'),
			url('../../../../../assets/images/backgrounds/dressing-room-floor-grid.svg'),
			linear-gradient(#A2B5C4, #D9E6EE),
			url('../../../../../assets/images/backgrounds/slaughterhouse-wall-grid.svg'),
			radial-gradient(rgba(white, 0), rgba(#AFBFCB, .29) 10%, rgba(#ACDDFF, 0.62)),
			linear-gradient(#788999, rgba(#C5D1DB, 0.09));

		background-size: 
			auto 36.72em,
			auto 10.52em,
			auto 10.52em,
			100% auto,
			122% calc(100% - 4em),
			auto 9em;
		
		background-position: 
			bottom 1.72em left calc(50% + 2em),
			bottom center,
			bottom center,
			center center,
			top -4em center,
			top center;
			
		background-repeat:
			no-repeat,
			repeat-x,
			no-repeat,
			repeat-y,
			no-repeat,
			no-repeat;

		.SpotErrors-text {
			left: auto;
			right: calc(50% - 24.5em);
		}

		.SpotErrors-errors { 
			.SpotErrors-error {
				&.meat-trays {
					bottom: 1em;
					left: calc(50% + 10em);
					width: 17em;
					height: 7.5em;
				}
				&.woodbox {
					bottom: 1.25em;
					left: calc(50% - 27.75em);
					height: 5.5em;
					width: 9em;
				}	
				&.meat-on-display {
					bottom: 21.25em;
					left: calc(50% - 14.6em);
					width: 5.5em;
					height: 5em;
				}
				&.tools {
					bottom: 17em;
					left: calc(50% + 2em);
					width: 3.5em;
					height: 3.5em;
				}
				&.floor-meat {
					bottom: 5.75em;
					left: calc(50% - 1.5em);
					width: 3.75em;
					height: 3.75em;
				}
			}
		}
	}
	
	&.production-slaughter-hall-2 {
		.SpotErrors-text {
			width: 37.3em;
			left: calc(50% - 37.3em / 2);
		}

		background-color: white;
		background-image:
			url('../../../../../assets/images/modules/tasks/spot-errors/production-slaughter-hall-2/background.svg'),
			url('../../../../../assets/images/backgrounds/dressing-room-floor-grid.svg'),
			linear-gradient(#A2B5C4, #D9E6EE),
			url('../../../../../assets/images/backgrounds/slaughterhouse-wall-grid.svg'),
			radial-gradient(rgba(white, 0), rgba(#AFBFCB, .29) 10%, rgba(#ACDDFF, 0.62)),
			linear-gradient(#788999, rgba(#C5D1DB, 0.09));

		background-size: 
			auto 35.25em,
			auto 10.52em,
			auto 10.52em,
			100% auto,
			122% calc(100% - 4em),
			auto 9em;
		
		background-position: 
			bottom 2.1em left calc(50% - 8em),
			bottom center,
			bottom center,
			center center,
			top -4em center,
			top center;

		background-repeat:
			no-repeat,
			repeat-x,
			no-repeat,
			repeat-y,
			no-repeat,
			no-repeat;

		.SpotErrors-errors { 
			.SpotErrors-error {
				&.drop {
					bottom: 33em;
					left: calc(50% - 28em);
					width: 8em;
					height: 4em;
				}
				&.gloves {
					bottom: 12.5em;
					left: calc(50% + 11em);
					height: 3.5em;
					width: 3.25em;
				}	
				&.soap {
					bottom: 19em;
					left: calc(50% - 11em);
					width: 4em;
					height: 2em;
				}
				&.thread {
					bottom: 22em;
					left: calc(50% - 21em);
					width: 3em;
					height: 2em;
				}
			}
		}
	}

	&.repetition-1-a-dressing-mistakes {
		.SpotErrors-text {
			width: 37.3em;
			left: calc(50% - 37.3em / 2.5);
		}

		background-color: transparent;
		background-image: url('../../../../../assets/images/modules/tasks/spot-errors/repetition-1-a-dressing-mistakes/background.svg');
		background-size: auto 35.25em;
		background-position: bottom 0.25em left calc(50% - 19.5em);
		background-repeat:no-repeat;

		.SpotErrors-errors { 
			.SpotErrors-error {
				&.watch {
					bottom: 15em;
					left: calc(50% - 16.25em);
					width: 2em;
					height: 2em;
				}
				&.hair {
					bottom: 28.5em;
					left: calc(50% - 22.75em);
					width: 2.5em;
					height: 2.5em;
				}
				&.dirt {
					bottom: 0.5em;
					left: calc(50% - 17.25em);
					width: 3em;
					height: 2em;
				}
				&.buttons {
					bottom: 24em;
					left: calc(50% - 20.25em);
					width: 2.5em;
					height: 2.5em;
				}
			}
		}
	}

	&.repetition-2-a-person {
		.SpotErrors-text {
			width: 18.4em;
			left: calc(50% - 37.3em / 2);
		}
		background-color: transparent;
		background-image:url('../../../../../assets/images/modules/tasks/spot-errors/repetition-2-a-person/background.svg');
		background-size: 19.3em auto;
		background-position: bottom -14.6em left calc(50% + 16.54em);
		background-repeat: no-repeat;

		.SpotErrors-errors {
			.SpotErrors-error {
				&.watch {
					bottom: 6.75em;
					left: calc(50% + 8.5em);
					width: 2em;
					height: 2em;
				}
				&.beard {
					bottom: 26.25em;
					left: calc(50% + 12.75em);
					width: 6em;
					height: 3.5em;
				}
				&.homeshirt {
					bottom: 4.25em;
					left: calc(50% + 9em);
					width: 15em;
					height: 22em;
				}
				&.cola {
					bottom: 2.5em;
					left: calc(50% + 18.5em);
					width: 7.5em;
					height: 3em;
				}
			}
		}
	}


	&.repetition-2-b-production {
		.SpotErrors-text {
			width: 37.3em;
			left: calc(50% - 37.3em / 2);
		}

		background-color: white;
		background-image:
			url('../../../../../assets/images/modules/tasks/spot-errors/repetition-2-b-production/background.svg'),
			url('../../../../../assets/images/backgrounds/dressing-room-floor-grid.svg'),
			linear-gradient(#A2B5C4, #D9E6EE),
			url('../../../../../assets/images/backgrounds/slaughterhouse-wall-grid.svg'),
			radial-gradient(rgba(white, 0), rgba(#AFBFCB, .29) 10%, rgba(#ACDDFF, 0.62)),
			linear-gradient(#788999, rgba(#C5D1DB, 0.09));

		background-size: 
			auto 40.2em,
			auto 10.52em,
			auto 10.52em,
			100% auto,
			122% calc(100% - 4em),
			auto 9em;
		
		background-position: 
			bottom left calc(50% - 7em),
			bottom center,
			bottom center,
			center center,
			top -4em center,
			top center;

		background-repeat:
			no-repeat,
			repeat-x,
			no-repeat,
			repeat-y,
			no-repeat,
			no-repeat;

		.SpotErrors-errors {
			.SpotErrors-error {
				&.drop {
					bottom: 36em;
					left: calc(50% - 30em);
					width: 10em;
					height: 3em;
				}
				&.glove {
					bottom: 12.5em;
					left: calc(50% + 15.25em);
					width: 2.5em;
					height: 2.5em;
				}
				&.screw {
					bottom: 20em;
					left: calc(50% - 8.5em);
					width: 4em;
					height: 1.5em;
				}
				&.hammer {
					bottom: 3.75em;
					left: calc(50% + 5.25em);
					width: 7em;
					height: 3.5em;
				}
			}
		}
	}

	&.repetition-2-b-production2 {
		.SpotErrors-text {
			width: 37.3em;
			left: calc(50% - 37.3em / 2);
		}

		background-color: white;
		background-image:
			url('../../../../../assets/images/modules/tasks/spot-errors/repetition-2-b-production2/background.svg'),
			url('../../../../../assets/images/backgrounds/dressing-room-floor-grid.svg'),
			linear-gradient(#A2B5C4, #D9E6EE),
			url('../../../../../assets/images/backgrounds/slaughterhouse-wall-grid.svg'),
			radial-gradient(rgba(white, 0), rgba(#AFBFCB, .29) 10%, rgba(#ACDDFF, 0.62)),
			linear-gradient(#788999, rgba(#C5D1DB, 0.09));

		background-size: 
			auto 37em,
			auto 10.52em,
			auto 10.52em,
			100% auto,
			122% calc(100% - 4em),
			auto 9em;
		
		background-position: 
			bottom 1.5em left calc(50% + 2em),
			bottom center,
			bottom center,
			center center,
			top -4em center,
			top center;

		background-repeat:
			no-repeat,
			repeat-x,
			no-repeat,
			repeat-y,
			no-repeat,
			no-repeat;

		.SpotErrors-errors {
			.SpotErrors-error {
				&.box {
					bottom: 1.5em;
					left: calc(50% + 11em);
					width: 15em;
					height: 6em;
				}
				&.meat {
					bottom: 8.15em;
					left: 50%;
					width: 3.5em;
					height: 3.5em;
				}
				&.overflowing {
					bottom: 15.25em;
					left: calc(50% - 18em);
					width: 10em;
					height: 6em;
				}
			}
		}
	}


	&.personal-hygiene-2-dressing-room {
		background-color: white;
		background-image:
			url('../../../../../assets/images/modules/tasks/spot-errors/personal-hygiene-2-dressing-room/background.svg'),
			url('../../../../../assets/images/backgrounds/dressing-room-floor-grid.svg'),
			linear-gradient(#A2B5C4, #D9E6EE),
			url('../../../../../assets/images/backgrounds/slaughterhouse-wall-grid.svg'),
			radial-gradient(rgba(white, 0), rgba(#AFBFCB, .29) 10%, rgba(#ACDDFF, 0.62)),
			linear-gradient(#788999, rgba(#C5D1DB, 0.09));

		background-size: 
			auto 35.25em,
			auto 10.52em,
			100% 10.52em,
			100% auto,
			122% calc(100% - 4em),
			auto 9em;
		
		background-position: 
			bottom 2.1em left calc(50% - 3.5em),
			bottom center,
			bottom center,
			center center,
			top -4em center,
			top center;
		;
		background-repeat:
			no-repeat,
			repeat-x,
			no-repeat,
			repeat-y,
			no-repeat,
			no-repeat;

		.SpotErrors-errors { 
			.SpotErrors-error {
				&.safety-glasses {
					bottom: 28em;
					left: calc(50% + 8.25em);
					width: 4em;
					height: 3em;
				}
				&.white-shoes {
					bottom: 5.5em;
					left: calc(50% + 5.5em);
					width: 4.5em;
					height: 4em;
				}
				&.hair-net {
					bottom: 27.75em;
					left: calc(50% + 5.25em);
					width: 3.25em;
					height: 3.25em;
				}
				&.apron {
					bottom: 9.7em;
					left: calc(50% + 9.9em);
					width: 2.5em;
					border-radius: 2em;
					height: 17.25em;
				}

			}
		}
	}


	&.production-2-production-hall {
		.GradientFrame {display: none;}
		background-color: white;
		background-image:
			url('../../../../../assets/images/modules/tasks/spot-errors/production-2-production-hall/lamps.svg'),
			url('../../../../../assets/images/backgrounds/frame-shade-square-sides.svg'),
			url('../../../../../assets/images/backgrounds/frame-shade-square-top-bottom.svg'),
			url('../../../../../assets/images/modules/tasks/spot-errors/production-2-production-hall/background.svg'),
			url('../../../../../assets/images/backgrounds/production-floor-pattern.svg'),
			linear-gradient(to bottom, rgba(#2A2926, 1), rgba(#5C5753, 0.94)),
			linear-gradient(135deg, 
				#EFEFEF, 
				#E8E7E7, 
				#C1C1C1, 
				#F5F5F5,
				#E8E4E4,
				#D3D3D3,
				#FFFFFF,
				#BFBFBF
			);
		background-size:
			47.89em auto,
			100% auto,
			auto 100%,
			auto 42.2em,
			auto 14.2em,
			100% 14.2em,
			100% 17em;
		
		background-position:
			top center,
			center center,
			center center,
			bottom 1.46em left calc(50% + 0.75em),
			bottom center,
			bottom center,
			bottom 14em center;

		background-repeat:
			no-repeat,
			repeat-y,
			repeat-x,
			no-repeat,
			repeat-x,
			no-repeat,
			no-repeat;

		.SpotErrors-text {
			left: calc(50% - (0.5 * 17.78em));
		}
		.SpotErrors-errors { 
			.SpotErrors-error {
				&.drop {
					bottom: 34.5em;
					left: calc(50% - 24.6em);
					width: 4em;
					height: 4em;
				}
				&.lamp {
					top: 0.5em;
					left: calc(50% + 9.6em);
					height: 2em;
					width: 15em;
					border-radius: 3em;
				}	
				&.glove-piece {
					bottom: 19.5em;
					left: calc(50% - 24.4em);
					width: 4em;
					height: 3em;
				}
				&.hammer {
					bottom: 3.25em;
					left: calc(50% - 2.5em);
					width: 7em;
					height: 3.5em;
				}
			}
		}
	}

	&.production-2-production-hall-2 {
		.GradientFrame {display: none;}
		background-color: white;
		background-image:
			url('../../../../../assets/images/backgrounds/frame-shade-square-sides.svg'),
			url('../../../../../assets/images/backgrounds/frame-shade-square-top-bottom.svg'),
			url('../../../../../assets/images/modules/tasks/spot-errors/production-2-production-hall-2/background.svg'),
			url('../../../../../assets/images/backgrounds/production-floor-pattern.svg'),
			linear-gradient(to bottom, rgba(#2A2926, 1), rgba(#5C5753, 0.94)),
			linear-gradient(135deg, 
				#EFEFEF, 
				#E8E7E7, 
				#C1C1C1, 
				#F5F5F5,
				#E8E4E4,
				#D3D3D3,
				#FFFFFF,
				#BFBFBF
			);
		background-size:
			100% auto,
			auto 100%,
			auto 42.66em,
			auto 14.2em,
			100% 14.2em,
			100% 17em;
		
		background-position:
			center center,
			center center,
			bottom 1.11em center,
			bottom center,
			bottom center,
			bottom 14em center;

		background-repeat:
			repeat-y,
			repeat-x,
			no-repeat,
			repeat-x,
			no-repeat,
			no-repeat;

		.SpotErrors-text {
			left: calc(50% - (0.5 * 17.78em));
		}
		.SpotErrors-errors { 
			.SpotErrors-error {
				&.drop {
					bottom: 34.5em;
					left: calc(50% - 24.6em);
					width: 4em;
					height: 4em;
				}
				&.screw {
					bottom: 15.25em;
					left: calc(50% - 11.1em);
					height: 3em;
					width: 3em;
				}	
				&.soap {
					bottom: 14.9em;
					left: calc(50% - 4.1em);
					width: 7em;
					height: 3em;
				}
				&.hammer {
					bottom: 2.2em;
					left: calc(50% - 20.5em);
					width: 6.5em;
					height: 4em;
				}
			}
		}
	}

	&.repetition-2-1a-person {
		background-color: transparent;
		background-image: url('../../../../../assets/images/modules/tasks/spot-errors/repetition-2-1a-person/background.svg');
		background-size: auto 36.94em;
		background-position: bottom 1em left calc(50% + 18em);
		.SpotErrors-errors { 
			.SpotErrors-error {
				&.watch {
					bottom: 16em;
					left: calc(50% + 12em);
					width: 3em;
					height: 3em;
				}
				&.hair {
					bottom: 30.5em;
					left: calc(50% + 18.75em);
					height: 3em;
					width: 3em;
				}	
				&.dirt {
					bottom: 0.75em;
					left: calc(50% + 12.5em);
					width: 3em;
					height: 3em;
				}
				&.buttons {
					bottom: 25em;
					left: calc(50% + 16em);
					width: 3em;
					height: 4em;
				}
			}
		}
	}

	&.repetition-2-1a-person2 {
		background-color: transparent;
		background-image: url('../../../../../assets/images/modules/tasks/spot-errors/repetition-2-1a-person2/background.svg');
		background-size: auto 41.09em;
		background-position: bottom -3.5em left calc(50% + 16em);
		.SpotErrors-errors { 
			.SpotErrors-error {
				&.apron {
					bottom: 15.5em;
					left: calc(50% + 15.5em);
					width: 3em;
					height: 4em;
				}
				&.glove {
					bottom: 10.75em;
					left: calc(50% + 9.5em);
					height: 3em;
					width: 3em;
				}	
				&.pants {
					bottom: 4.75em;
					left: calc(50% + 11.75em);
					width: 3em;
					height: 3em;
				}
				&.sleeve {
					bottom: 19em;
					left: calc(50% + 19.5em);
					width: 3em;
					height: 3em;
				}
			}
		}
	}

	&.repetition-2-1b-production-hall {
		.GradientFrame {display: none;}
		background-color: white;
		background-image:
			url('../../../../../assets/images/modules/tasks/spot-errors/repetition-2-1b-production-hall/lamps.svg'),
			url('../../../../../assets/images/backgrounds/frame-shade-square-sides.svg'),
			url('../../../../../assets/images/backgrounds/frame-shade-square-top-bottom.svg'),
			url('../../../../../assets/images/modules/tasks/spot-errors/repetition-2-1b-production-hall/background.svg'),
			url('../../../../../assets/images/backgrounds/production-floor-pattern.svg'),
			linear-gradient(to bottom, rgba(#2A2926, 1), rgba(#5C5753, 0.94)),
			linear-gradient(135deg, 
				#EFEFEF, 
				#E8E7E7, 
				#C1C1C1, 
				#F5F5F5,
				#E8E4E4,
				#D3D3D3,
				#FFFFFF,
				#BFBFBF
			);
		background-size:
			47.89em auto,
			100% auto,
			auto 100%,
			auto 42.66em,
			auto 14.2em,
			100% 14.2em,
			100% 17em;
		
		background-position:
			top center,
			center center,
			center center,
			bottom 1.11em center,
			bottom center,
			bottom center,
			bottom 14em center;

		background-repeat:
			no-repeat,
			repeat-y,
			repeat-x,
			no-repeat,
			repeat-x,
			no-repeat,
			no-repeat;

		.SpotErrors-text {
			left: calc(50% - (0.5 * 17.78em));
		}
		.SpotErrors-errors { 
			.SpotErrors-error {
				&.drop {
					bottom: 34.5em;
					left: calc(50% - 24.6em);
					width: 4em;
					height: 4em;
				}
				&.screw {
					bottom: 15.25em;
					left: calc(50% - 11.1em);
					height: 3em;
					width: 3em;
				}	
				&.lamp {
					top: 0.5em;
					left: calc(50% + 9.6em);
					height: 2em;
					width: 15em;
					border-radius: 3em;
				}
				&.hammer {
					bottom: 2.2em;
					left: calc(50% - 20.5em);
					width: 6.5em;
					height: 4em;
				}
			}
		}
	}
}