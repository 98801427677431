@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/buttons';
@import '../../styles/backgrounds';
@import '../../styles/animations';
.FacilitatorSelectGame {

	.FacilitatorSelectGame-header {
		width: 100%;
		height: 12em;
	}
	.FacilitatorSelectGame-content {
		position: absolute;
		width: 100%;
		top: 12em;
		bottom: 0;
		padding: 1.67em 2em;
		overflow: auto;
		color: #525252;
		@include hide-scrollbar();

		.FacilitatorSelectGame-title {
			span {
				font-size: 2em;
				font-weight: bold;
			}
		}
		.FacilitatorSelectGame-text {
			padding: 2em;
			text-align: center;
			span {
				font-size: 1.5em;
			}
		}		

		.FacilitatorSelectGame-games {
			width: 68em;
			@include flex('space-between', 'flex-start', 'row');
			flex-wrap: wrap;
			margin: 2em auto 1em auto;
			.FacilitatorSelectGame-game {
				width: 33em;				
				background-color: #f2482b;
				border: 0.1em solid #f2482b;
				border-radius: 0.5em;
				text-align: left;
				margin-bottom: 1em;
				.FacilitatorSelectGame-gameHeader {
					padding: 0.5em 1em;
					span {
						font-size: 1.5em;
						font-weight: bold;
						color: white;
					}
				}
				.FacilitatorSelectGame-gameBody {
					background-color: white;
					border-bottom-left-radius: 0.5em;
					border-bottom-right-radius: 0.5em;
					padding: 1em;
					.FacilitatorSelectGame-gameInfo {
						margin-bottom: 0.5em;
						span, a {
							display: inline-block;
							vertical-align: top;
							font-size: 1.25em;
							color: #525252;
						}
						span:first-of-type {
							width: 5em;
						}
					}
					.FacilitatorSelectGame-gameSelectBtn {
						margin: 1em auto 0 auto;
						width: 10em;
					}
					

				}
			}
		}
	}
}