@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/backgrounds';
@import '../../../../../styles/animations';
.Order {
	position: relative;
	height: 100%;
	padding: 4.5em 0 0.5em 0;
	overflow: hidden;
	@include flex('center', 'center');
	.Order-content {
		position: relative;
		width: 42em;
		max-height: 37em;
		// margin-left: calc(50% - 21em);
		padding: 2em 1.7em 5.6em 1.7em;
		background-color: rgba(white, 0.8);
		border-radius: 1.11em;
		border: 0.17em solid rgba(#12283E, 0.8);
		overflow: auto;
		scroll-behavior: smooth;
		-webkit-overflow-scrolling: touch;
		@include hide-scrollbar();
		@include box-shadow(0, 0.17em, 0.33em, 0, #12283E);
		.Order-intro {
			position: relative;
			width: 100%;
			margin-bottom: 1em;
		}
		.Order-items {
			position: relative;
			width: 100%;
			&.size-3 {height: calc(3 * 3em + 2 * 1.2em);}
			&.size-4 {height: calc(4 * 3em + 3 * 1.2em);}
			&.size-5 {height: calc(5 * 3em + 4 * 1.2em);}
			&.size-6 {height: calc(6 * 3em + 5 * 1.2em);}
			
			.Order-container {
				position: absolute;
				top: 0;
				height: 3em;
				border-radius: 1.67em;
				@include flex('flex-start', 'center');
				.Order-containerNumber {
					display: inline-block;
					// width: 3em;
					// height: 100%;
					// background-color: $blueLight;
					// border-radius: 0.44em;
					// color: $darkBlue;
					// margin-right: 0.9em;
					// @include flex('center', 'center');
					span {
						display: inline-block;
						width: 1.75em;
						font-size: 1.67em;
						font-weight: bold;
						text-align: center;
					}
				}
				&.pos-2 {top: 4.2em;}
				&.pos-3 {top: calc(2 * 4.2em);}
				&.pos-4 {top: calc(3 * 4.2em);}
				&.pos-5 {top: calc(4 * 4.2em);}
				&.final {
					left: 0;
					width: 19.5em;
					background-color: rgba(white, 1);
					@include box-shadow-inset(0, 0, 0.17em, 0, rgba(black, 0.54));

					&::after {
						content: '';
						position: absolute;
						top: 0;
						right: 0;
						width: 17em;
						height: 3em;
						border-radius: 1.67em;
						pointer-events: none;
						@include box-shadow-inset(0, 0, 0.17em, 0, rgba(black, 0.54));
					}
					span {
						display: inline-block;
						width: 1.75em;
						font-size: 1.67em;
						font-weight: bold;
						text-align: center;
					}
				}
				&.initial {
					left: 20.5em;
					width: 17em;
					background-color: rgba(white, 0.6);
					border: 0.11em dashed rgba(#12283E, 0.6);
	
				}
			}
			.Order-item {
				position: absolute;
				top: 0;
				left: 2.5em;
				width: 17em;
				height: 3em;
				@include flex('center', 'center');
				@include no-select();
				&.pos-2 {top: 4.2em;}
				&.pos-3 {top: calc(2 * 4.2em);}
				&.pos-4 {top: calc(3 * 4.2em);}
				&.pos-5 {top: calc(4 * 4.2em);}
				&.final {
					@include translate(0);
					
				}
				&.initial {
					@include translate(18em);
					width: 17em;
				}
			
				&.correct {
					background-color: greenyellow;
					cursor: not-allowed;
				}
				&.animate {
					@include transitionTransform(1s, ease-in-out, 0.5s);
				}
			}
		}
		.Order-doneBtn {
			position: absolute;
			bottom: 1.33em;
			left: calc(50% - 0.5 * 8.75em);
			width: 8.75em;
		}
	}

	

	.Order-scrollDown {
		position: fixed;
		bottom: 0.1em;
		left: calc(50% - 1.375em);
		width: 2.75em;
		height: 2.75em;
		// background-image: url('../../../../assets/images/icons/icon-down.svg');
		cursor: pointer;
	}
}