@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/buttons';
@import '../../styles/backgrounds';
@import '../../styles/animations';
.FacilitatorPlayers {
	position: relative;
	padding-top: 3em;

	.FacilitatorPlayers-toggleInactivePlayers {
		position: absolute;
		left: 0em;
		top: 0;
		padding-left: 2em;
		cursor: pointer;
		span {
			font-weight: 600;
		}
		&::after {
			content: '';
			position: absolute;
			width: 1.2em;
			height: 1.2em;
			top: calc(50% - 0.5em);
			left: 0em;
			border: 0.1em solid black;
		}
		&.show::after {
			background-image: url('../../assets/images/icons/icon-checkmark.svg');
			background-size: 90% auto;
		}
	}
	.FacilitatorPlayers-statsBtn {
		position: absolute;
		right: 0em;
		top: 0;

	}
	.FacilitatorPlayers-cell {
		&.id {
			width: 12%;
			text-align: left;
		}
		&.created,
		&.lastLogin {
			width: 10%;
			text-align: center;
		}
		&.moduleGroup {
			width: 17%;
			text-align: center;
		}
		&.lastModule {
			width: 17%;
			text-align: right;
		}
	}
	.FacilitatorPlayers-header {
		width: 100%;
		height: 2.33em;
		color: white;
		background-color: #f2482b;
		padding: 0 0.75em;
		@include flex('space-between', 'center');
		.FacilitatorPlayers-cell {
			span {
				display: inline-block;
				vertical-align: middle;
				position: relative;
				font-size: 1.39em;
				font-weight: 600;

			}
			&.id,
			&.lastlogin {
				cursor: pointer;
			}
			&.sortedBy {
				span::after {
					content: '';
					display: inline-block;
					vertical-align: middle;
					width: 1em;
					height: 1em;
					background-size: 0.6em auto;
					background-image: url('../../assets/images/icons/icon-sort-desc.svg');
				}
				&.ASC span::after {background-image: url('../../assets/images/icons/icon-sort-asc.svg');}
			}
		}
	}
	.FacilitatorPlayers-body {
		width: 100%;
		.FacilitatorPlayers-row {
			width: 100%;
			height: 2.5em;
			padding: 0 0.75em;
			color: #525252;
			cursor: pointer;
			&:hover {
				background-color: rgba(#f2482b, 0.3);
			}
			&:nth-child(odd) {
				background-color: rgba(#f2482b, 0.2);
				&:hover {
					background-color: rgba(#f2482b, 0.3);
				}
			}
			@include flex('space-between', 'center');
			.FacilitatorPlayers-cell {
				&.id {
					span:first-of-type {
						display: inline-block;
						vertical-align: middle;
						width: 7em;
						font-weight: 600;
						text-decoration: underline;
					}
					span:nth-child(2) {
						display: inline-block;
						vertical-align: middle;
						border: 1px solid black;
						background-color: white;
						border-radius: 0.25em;
						padding: 0.2em 0.3em;
					}
				}
				&.moduleGroup.completed {
					height: 100%;
					background-image: url('../../assets/images/icons/icon-checkmark.svg');
					background-size: auto 70%;
					@include flex('center', 'center');
					&.withDate {
						background-position: left 20% center;
					}
				}
				&.moduleGroup.disabled {
					height: 100%;
					background-image: url('../../assets/images/icons/icon-lock.svg');
					background-size: auto 70%;
					@include flex('center', 'center');
				}
			}

			&.inactive {
				background-color: rgba(#d3d3d3, 0.3);
				&:hover {
					background-color: rgba(#c0c0c0, 0.3);
				}
			}
		}
	}
}
