@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/backgrounds';
@import '../../../../../styles/animations';
.PaperDoll {
	position: relative;
	height: 100%;
	overflow: auto;

	.PaperDoll-intro {
		position: absolute;
		width: 23.5em;
		top: 7.7em;
		left: 3em;
		margin: 0 auto;
		padding: 1.25em 2em;
		background-color: rgba(white, 0.9);
		border-radius: 1.11em;
		border: 0.17em solid rgba(#12283E, 0.9);
		@include box-shadow(0, 0.17em, 0.33em, 0, #12283E);

		.PaperDoll-panel {
			position: relative;
			width: 100%;
			height: 14.25em;
			z-index: 2;
			border-top: 0.17em solid rgba(#12283E, 0.9);
			margin-top: 0.5em;
			padding: 1em;

			.PaperDoll-panelHeader {
				position: relative;
				width: 100%;
				height: 2.25em;
				background-color: #10779E;
				background-image: linear-gradient(#10779E, #15476A);
				background-size: 100% 100%;
				border-top: calc(0.11em + 1px) solid #7DBDE8;
				box-sizing: content-box;
				@include flex('flex-start', 'flex-start');
				@include hide-scrollbar();
			}
			.PaperDoll-availableItems {
				position: relative;
				width: 100%;
				height: calc(100% - (2.25em + 0.11em + 1px));
			}	
		}
	}
	
	.PaperDoll-selectedItems,
	.PaperDoll-availableItems {
		.PaperDoll-item {
			position: absolute;
			text-align: left;
			svg {width: 100%; height: auto;}
			&.selected {@include opacity(0.25);}
			&.coat {width: 6em}
			&.cutting-glove {width: 5em}
			&.earplugs {width: 5em;}
			&.earplugs-wireless {width: 4em;}
			&.glasses {width: 9.19em;}
			&.hairnet {width: 6em;}
			&.phone {width: 5em;}
			&.watch {width: 4em;};
			&.watch2 {width: 3.94em;};
			&.white-shoes {width: 8.37em;}
		}
	}

	.PaperDoll-selectedItems {
		position: absolute;
		left: calc(50% + 14em);
		bottom: 1.5em;
		z-index: 1;
		width: 11.16em;
		height: 35em;
		background-image: url('../../../../../assets/images/modules/tasks/paper-doll/avatar.svg');
		
		.PaperDoll-item {
			&.hairnet {width: 6em; bottom: 14.7em; left: calc(50% - 3em);}
			&.phone {width: 3em; bottom: 15em; left: calc(50% - 3em);}
			&.white-shoes {width: 10em; bottom: -0.1em; left: calc(50% - 5em);}
		}
	}

	.PaperDoll-confirmBtn {
		position: fixed;
		right: calc(50% - 0.5 * 8.78em);
		bottom: 1.44em;
		height: 2.67em;
		width: 8.78em;
		cursor: pointer;
		@include button-yellow();
		span {
			font-size: 1.22em;
			font-weight: bold;
		}
	}


	&.completed {
		background: none;
		overflow: hidden;
		.PaperDoll-panel {
			display: none;
			bottom: 0;
			height: 2.25em;
			.PaperDoll-availableItems {
				display: none;
			}
		}
		.PaperDoll-tools {
			bottom: 0em;
		}
	}

	&.completed {
		background: none;
	}

	&.repetition-2-a-paper-doll-items {
		.PaperDoll-selectedItems .PaperDoll-item {
			&.cutting-glove {width: 6.6em; bottom: 12.3em; left: calc(50% + 1.05em); @include rotate(56.5deg);}
			&.coat {width: 10.25em; bottom: 13.15em; left: calc(50% - 4.7em);}
			&.earplugs {width: 3.5em; bottom: 24.9em; left: calc(50% - 0.9em);}
			&.earplugs-wireless {width: 0.4em; bottom: 29.95em; left: calc(50% + 2.15em);}
			&.hairnet {width: 5.9em; bottom: 29.65em; left: calc(50% - 2.65em);}
			&.phone {width: 1.4em; bottom: 13.2em; left: calc(50% - 4.1em); }
			&.watch {width: 1.1em; bottom: 15.25em; left: calc(50% - 4.75em);};
		}

		.PaperDoll-panel .PaperDoll-availableItems .PaperDoll-item {
			&.coat {left: calc(50% + 4.5em); top: 0em;}
			&.cutting-glove {left: calc(50% + 3em); top: 9em;}
			&.earplugs {left: calc(50% - 7em); top: 8.5em;}
			&.earplugs-wireless {left: calc(50% - 9em); top: 5em;}
			&.hairnet {left: calc(50% - 9em); top: 0em;}
			&.phone {left: calc(50% - 3em); top: 3em;}
			&.watch {left: calc(50%); top: 0em;};
		}
	}



	&.repetition-2-2a-paper-doll-items {
		.PaperDoll-selectedItems  {
			background-image: url('../../../../../assets/images/modules/tasks/paper-doll/coat-b.svg'), url('../../../../../assets/images/modules/tasks/paper-doll/avatar-2.svg');
			background-size: 10.25em auto, contain;
			background-position: bottom 13.15em left calc(50% + 0.3em), top center;
		}
		.PaperDoll-panel .PaperDoll-availableItems .PaperDoll-item {
			&.earplugs {left: calc(50% - 9.5em); top: 8.5em;}
			&.glasses {top: 0.5em; left: calc(50% + 1em)}
			&.hairnet {left: calc(50% - 9em); top: 0em;}
			&.phone {left: calc(50% - 5em); top: 4em;}
			&.watch2 {left: calc(50% + 6em); top: 5em;};
			&.white-shoes {top: 7em; left: calc(50% - 0.5em); }
		}

		.PaperDoll-selectedItems .PaperDoll-item {
			&.earplugs {width: 3.5em; bottom: 24.9em; left: calc(50% - 0.9em);}
			&.glasses {width: 4em; bottom: 30em; left: calc(50% - 2.5em)}
			&.hairnet {width: 5.9em; bottom: 29.65em; left: calc(50% - 2.65em);}
			&.phone {width: 1.4em; bottom: 13.2em; left: calc(50% - 4.1em); }
			&.watch2 {
				width: 0.8em; bottom: 15.3em; left: calc(50% - 4.5em);
				@include rotate(-40deg);
			};			
		}
	}
}