@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/backgrounds';
@import '../../../../../styles/animations';
.OrderDndContainer {
	position: relative;
	width: 100%;
	height: 100%;
	&.hover {
		@include opacity(0.4);

		background-color: $blueLight;
		position: absolute;
		top: 0;
		right: 0;
		width: 17em;
		height: 3em;
		border-radius: 1.67em;
	}
}