@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/backgrounds';
@import '../../../styles/animations';
.Popup {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#092335, 0.6);
	z-index: 13;
	@include flex('center', 'center');

	.Popup-content {
		position: relative;
		width: 18em;
		min-height: 10em;
		color: #11466B;
		background-color: white;
		padding: 1em;
		border-radius: 1em;
		text-align: center;
		@include box-shadow(0, 0, 0.66em, 0, rgba(#000000, 0.2));

		.Popup-title {
			font-size: 1.56em;
			font-weight: 700;
			margin-bottom: 0.75em;
		}
		.Popup-text {
			font-size: 1em;
			font-weight: 500;
			margin-top: 0.5em;
		}

		.Popup-buttons {
			@include flex('space-between');
		}
	}

	&.facilitator {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(#000000, 0.5);
		@include flex('center', 'center');
		z-index: 5;
		.Popup-content {
			width: 40em;
			background-color: white;
			border-radius: 0.83em;
			padding: 2em 1em;
		}

		.Popup-buttons {
			@include flex('space-between', 'center');
			width: 20em;
			> div {
				width: 8em;
				height: 2.67em;
				span {
					font-size: 1.2em;
					font-weight: bold;
					text-transform: uppercase;
				}
			}
		}
	}

	&.login {
		.Popup-content {
			background-color: white;
			width: 25.5em;
			height: 10.67em;
			border: 0.16em solid black;
			border-radius: 1.2em;
		}

		.Popup-buttons {
			margin: 0 auto;
			@include flex('space-between', 'center');
			width: 12.56em;
			> div {
				width: 5.78em;
				height: 2.67em;
				span {
					font-size: 1.2em;
					font-weight: bold;
					text-transform: uppercase;
				}
			}
		}
	}

	&.try-again {
		.Popup-content {
			width: 20em;
		}
		.Popup-buttons {
			margin: 0 auto;
			width: 85%;
			> div {
				width: 7em;
			}
		}
	}

	&.spot-errors-result,
	&.order-result {
		.Popup-content {
			@include flex('center', 'center');
			.Popup-title { 
				font-size: 1.5em;
			}
			.Popup-text { 
				margin: 0;
				font-size: 1.25em;
			}
		}
	}
}
