@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/backgrounds';
@import '../../../../../styles/animations';
.MultipleChoice {
	position: relative;
	height: 100%;
	padding: 4.5em 0 0.5em 0;
	overflow: hidden;
	@include flex('center', 'center');
	.MultipleChoice-content {
		width: 100%;
		max-height: 37em;
		overflow: auto;
		@include hide-scrollbar();
		.MultipleChoice-intro {
			position: relative;
			width: 100%;
		}

		.MultipleChoice-options {
			position: relative;
			width: 21.5em;
			margin: 0.75em auto auto auto;
			padding: 0 1.5em;

			.MultipleChoice-optionsWrap {
				position: relative;			
				.MultipleChoice-option {
					position: relative;
					width: 100%;
					height: 100%;
					text-align: center;
					padding: 0.75em 1.25em 0.5em 1.25em;
					margin-bottom: 1.25em;
					cursor: pointer;
					@include flex('center', 'center');
					@include no-select();
					&.animateCorrect {@include bounce();}
					&.animateWrong {@include wobble();}
					&.disabled {cursor: not-allowed;}
					span:first-of-type {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
					span:last-of-type {
						font-weight: bold;
					}
					.MultipleChoice-audio {
						position: absolute;
						right: 0.5em;
						top: calc(50% - 1em);
					}
				}
			}	
		}		
	}

	&:not(.images) .MultipleChoice-content {
		width: 30.83em;
		background-color: rgba(white, 0.9);
		border-radius: 1.11em;
		padding: 1.25em 2em;
		border: 0.17em solid rgba(#12283E, 0.9);
		@include box-shadow(0, 0.17em, 0.33em, 0, #12283E);
		.MultipleChoice-intro {
			padding-bottom: 0.75em;
			&::after {
				content: '';
				position: absolute;
				top: 100%;
				width: 100%;
				left: 0;
				height: 0.17em;
				background-color: #12283E;
			}
		}
		.MultipleChoice-options {
			.MultipleChoice-optionsWrap {
				margin-top: 1.25em;
				.MultipleChoice-option {
					min-height: 3em;
					padding-right: 3.5em;
					@include button-blue();
					// &.selected {background-color: lightblue;}
					&.correct,
					&.animateCorrect {
						@include button-green();
					}
					&.wrong,
					&.animateWrong {
						@include button-red();
					}
					&.completed {
						// background-color: grey;
						cursor: not-allowed;
					}
				}
			}
		}
	}

	&.images .MultipleChoice-content {
		.MultipleChoice-intro {
			width: 37.33em;
			margin: 0 auto;
			padding: 1.25em 2em;
			background-color: rgba(white, 0.9);
			border-radius: 1.11em;
			border: 0.17em solid rgba(#12283E, 0.9);
			@include box-shadow(0, 0.17em, 0.33em, 0, #12283E);
		}
		.MultipleChoice-options {
			padding: 0;
			.MultipleChoice-optionsWrap {
				.MultipleChoice-option { 
					min-height: auto;
					background: none;
					background-size: contain;
					background-position: center center;
					background-repeat: no-repeat;
					margin: 0;
					padding: 0;
					border-radius: 0;
					@include box-shadow(0, 0, 0, 0, transparent);
					&::after {
						content: '';
						display: none;
						position: absolute;
						bottom: -0.75em;
						right: -0.75em;
						width: 2.11em;
						height: 2.11em;
						background-size: contain;
					}
					&.selected,
					&.correct,
					&.animateCorrect,
					&.wrong,
					&.animateWrong {
						@include box-shadow(0, 0, 0, 0, transparent);
						&::after {display: block;}
					}
					&.correct,
					&.animateCorrect {
						&::after {
							background-image: url('../../../../../assets/images/icons/icon-corrrect.svg');
						}
					}
					&.wrong,
					&.animateWrong {
						&::after {
							background-image: url('../../../../../assets/images/icons/icon-wrong.svg');
						}
					}
					&.completed {
						cursor: not-allowed;
					}
				}
			}
		}
	}

	&.personal-hygiene-hair-and-beard .MultipleChoice-content {
		.MultipleChoice-intro {
			margin-bottom: 2em;
		}
		.MultipleChoice-options {
			width: 37.33em;
			.MultipleChoice-optionsWrap {
				@include flex('center', 'center');
				flex-wrap: wrap;
				.MultipleChoice-option {
					width: 10.89em;
					height: 12.17em;
					margin: 0 0.4em 0.8em 0.4em;
					background-color: white;
					border: 0.25em solid #12283E;
					border-radius: 1.75em;
					span:first-of-type {
						background-size: 7.71em auto;
						background-position: bottom -0.25em center;
					}
					&.option-1 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/personal-hygiene-hair-and-beard/option-1.svg');
					}
					&.option-2 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/personal-hygiene-hair-and-beard/option-2.svg');
					}
					&.option-3 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/personal-hygiene-hair-and-beard/option-3.svg');
					}	
					&.option-4 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/personal-hygiene-hair-and-beard/option-4.svg');
					}	
					&.option-5 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/personal-hygiene-hair-and-beard/option-5.svg');
					}	
					&.correct,
					&.animateCorrect {
						background-image: linear-gradient(#C2F2C2, #36DD36, #25D025);
					}
					&.wrong,
					&.animateWrong {
						background-image: linear-gradient(#FFB9C8, #F55679, #F73963);
					}
				}
			}	
		}
	}

	&.personal-hygiene-jewelry .MultipleChoice-content {
		.MultipleChoice-intro {
			margin-bottom: 2em;
		}
		.MultipleChoice-options {
			width: 37.33em;
			.MultipleChoice-optionsWrap {
				@include flex('center', 'center');
				flex-wrap: wrap;
				.MultipleChoice-option {
					width: 10.89em;
					height: 12.17em;
					margin: 0 0.4em 0.8em 0.4em;
					background-color: white;
					border: 0.25em solid #12283E;
					border-radius: 1.75em;
					span:first-of-type {
						background-size: 7.71em auto;
						background-position: bottom -0.25em center;
					}
					&.option-1 span:first-of-type {
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/personal-hygiene-jewelry/option-1.svg');
					}
					&.option-2 span:first-of-type {
						border-bottom-left-radius: 1.5em;
						border-top-left-radius: 1.5em;
						background-size: 105% auto;
						background-position: top -0.5em left -0.5em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/personal-hygiene-jewelry/option-2.svg');
					}
					&.option-3 span:first-of-type {
						border-bottom-left-radius: 1.5em;
						border-bottom-right-radius: 1.5em;
						background-size: 110% auto;
						background-position: top -1em center;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/personal-hygiene-jewelry/option-3.svg');
					}	
					&.option-4 span:first-of-type {
						background-size: 120% auto;
						background-position: center left -2em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/personal-hygiene-jewelry/option-4.svg');
					}	
					&.option-5 span:first-of-type {
						border-bottom-left-radius: 1.5em;
						border-bottom-right-radius: 1.5em;
						background-size: contain;
						background-position: bottom;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/personal-hygiene-jewelry/option-5.svg');
					}	
					&.correct,
					&.animateCorrect {
						background-image: linear-gradient(#C2F2C2, #36DD36, #25D025);
					}
					&.wrong,
					&.animateWrong {
						background-image: linear-gradient(#FFB9C8, #F55679, #F73963);
					}
				}
			}	
		}
	}

	&.personal-hygiene-2-jewelry .MultipleChoice-content {
		.MultipleChoice-intro {
			margin-bottom: 2em;
		}
		.MultipleChoice-options {
			width: 37.33em;
			.MultipleChoice-optionsWrap {
				@include flex('center', 'center');
				flex-wrap: wrap;
				.MultipleChoice-option {
					width: 10.89em;
					height: 12.17em;
					margin: 0 0.4em 0.8em 0.4em;
					background-color: white;
					border: 0.25em solid #12283E;
					border-radius: 1.75em;
					span:first-of-type {
						background-size: 7.71em auto;
						background-position: bottom -0.25em center;
					}
					&.option-1 span:first-of-type {
						background-size: contain;
						background-position: center center;
						background-size: 9.2em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/personal-hygiene-2-jewelry/option-1.svg');
					}
					&.option-2 span:first-of-type {
						border-bottom-left-radius: 1.5em;
						border-top-left-radius: 1.5em;
						background-size: 105% auto;
						background-position: top -0.5em left -0.5em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/personal-hygiene-2-jewelry/option-2.svg');
					}
					&.option-3 span:first-of-type {
						border-bottom-left-radius: 1.5em;
						border-bottom-right-radius: 1.5em;
						background-size: 110% auto;
						background-position: top -1em center;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/personal-hygiene-2-jewelry/option-3.svg');
					}	
					&.option-4 span:first-of-type {
						background-size: 120% auto;
						background-position: center left -2em;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/personal-hygiene-2-jewelry/option-4.svg');
					}	
					&.option-5 span:first-of-type {
						border-bottom-left-radius: 1.5em;
						border-bottom-right-radius: 1.5em;
						background-size: contain;
						background-position: bottom;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/personal-hygiene-2-jewelry/option-5.svg');
					}	
					&.correct,
					&.animateCorrect {
						background-image: linear-gradient(#C2F2C2, #36DD36, #25D025);
					}
					&.wrong,
					&.animateWrong {
						background-image: linear-gradient(#FFB9C8, #F55679, #F73963);
					}
				}
			}	
		}
	}

	&.personal-hygiene-nails .MultipleChoice-content {
		.MultipleChoice-intro {
			margin-bottom: 2em;
		}
		.MultipleChoice-options {
			width:50em;
			.MultipleChoice-optionsWrap {
				@include flex('space-between', 'center');
				.MultipleChoice-option {
					width: 10.89em;
					height: 12.17em;
					margin: 0 0.4em 0.8em 0.4em;
					background-color: white;
					border: 0.25em solid #12283E;
					border-radius: 1.75em;
					span:first-of-type {
						background-size: contain;
						background-position: bottom center;
					}
					&.option-1 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/personal-hygiene-nails/option-1.svg');
					}
					&.option-2 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/personal-hygiene-nails/option-2.svg');
					}
					&.option-3 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/personal-hygiene-nails/option-3.svg');
					}	
					&.option-4 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/personal-hygiene-nails/option-4.svg');
					}	
					&.correct,
					&.animateCorrect {
						background-image: linear-gradient(#C2F2C2, #36DD36, #25D025);
					}
					&.wrong,
					&.animateWrong {
						background-image: linear-gradient(#FFB9C8, #F55679, #F73963);
					}
				}
			}	
		}
	}

	&.personal-hygiene-lockers {
		background-color: white;
		background-image:
			url('../../../../../assets/images/modules/tasks/multiple-choice/personal-hygiene-lockers/background.svg'),
			url('../../../../../assets/images/backgrounds/dressing-room-floor-grid.svg'),
			linear-gradient(#A2B5C4, #D9E6EE),
			url('../../../../../assets/images/backgrounds/slaughterhouse-wall-grid.svg'),
			radial-gradient(rgba(white, 0), rgba(#AFBFCB, .29) 10%, rgba(#ACDDFF, 0.62)),
			linear-gradient(#788999, rgba(#C5D1DB, 0.09))
		;
		background-size: 
			auto 36.1em,
			auto 10.52em,
			100% 10.52em,
			100% auto,
			122% calc(100% - 4em),
			auto 9em;
		
		background-position: 
			bottom 2.13em left calc(50% - 3.5em),
			bottom center,
			bottom center,
			center center,
			top -4em center,
			top center;
		;
		background-repeat:
			no-repeat,
			repeat-x,
			no-repeat,
			repeat-y,
			no-repeat,
			no-repeat;

		&::after {
			// Covers first background image
			content: '';
			position: absolute;
			bottom: 2.13em;
			left: 0;
			width: 100%;
			height: 36.1em;
			background-image: url('../../../../../assets/images/text/private-en.svg');
			background-size: auto 0.8em;
			background-position: top 1em left calc(50% + 9.6em);
			pointer-events: none;
		}
		&.da::after,
		&.de::after {
			background-image: url('../../../../../assets/images/text/private-da.svg');
			background-position: top 1em left calc(50% + 9.4em);
		}
		&.de::after {
			background-image: url('../../../../../assets/images/text/private-de.svg');
		}
		&.pl::after {
			background-image: url('../../../../../assets/images/text/private-pl.svg');
		}

		.MultipleChoice-content {
			position: absolute;
			top: 6.11em;
			left: calc(50% - 26.8em);
			width: 23.5em;
			border-radius: 1.11em;
			background-color: white;
			border: 0.17em solid #12283E;
			@include box-shadow(0, 0.17em, 0.33em, 0, #12283E);

			.MultipleChoice-intro { 
				width: 100%;
				background-color: transparent;
				border-radius: 0;
				border-style: none;
				padding-bottom: 0.25em;
				@include box-shadow(0, 0, 0, 0, #12283E);
				
			}
			.MultipleChoice-options {
				width: 100%;
				height: 21.75em;
				padding: 0 1.75em;
				.MultipleChoice-optionsWrap {
					border-top: 0.25em solid rgba(#12283E, 0.8);
					width: 100%;
					height: 100%;
					.MultipleChoice-option {
						position: absolute;
						span:first-of-type {
							background-size: contain;
						}
						&.option-1 {
							width: 4.66em;
							height: 5.25em;
							bottom: 2.4em;
							right: 1.4em;
							span:first-of-type {
								background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/personal-hygiene-lockers/option-1.svg');
							
							}
						}
						&.option-2 {
							top: 11em;
							left: 8.3em;
							width: 4em;
							height: 2.5em;
							span:first-of-type {
								background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/personal-hygiene-lockers/option-2.svg');
							}
							&::after {
								content: '';
								bottom: 1em;
								right: 1em;
								
							}
						}
						&.option-3 {
							top: 0.5em;
							right: 0;
							width: 6.285em;
							height: 3.155em;
							span:first-of-type {
								background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/personal-hygiene-lockers/option-3.svg');
							}
						}
						&.option-4 {
							bottom: 1em;
							left: 1em;
							width: 6.14em;
							height: 6.64em;
							span:first-of-type {
								background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/personal-hygiene-lockers/option-4.svg');
							}
						}
						&.option-5 {
							top: 1em;
							left: 1em;
							width: 5.9em;
							height: 9.1em;
							span:first-of-type {
								background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/personal-hygiene-lockers/option-5.svg');
							}
						}
						&.option-6 {
							top: 6.9em;
							right: 0;
							width: 5.38em;
							height: 4.21em;
							span:first-of-type {
								background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/personal-hygiene-lockers/option-6.svg');
							}
						}
					}
				}
			}
		}
	}
	
	
	&.production-clothes .MultipleChoice-content {
		.MultipleChoice-options {
			width: 40em;
			
			.MultipleChoice-optionsWrap {
				@include flex('center', 'center');
				flex-wrap: wrap;
				.MultipleChoice-option {
					width: 10.89em;
					height: 12.12em;
					margin: 0 0.4em 0.8em 0.4em;
					background-color: white;
					border: 0.25em solid #12283E;
					border-radius: 1.45em;
					
					span:first-of-type {
						background-position: top center;
					}
					&.option-1 span:first-of-type {
						background-position: center;
						background-size: 90% 90%;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/production-clothes/option-1.svg');
					}
					&.option-2 span:first-of-type {
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/production-clothes/option-2.svg');
					}
					&.option-3 span:first-of-type {
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/production-clothes/option-3.svg');
					}
					&.option-4 span:first-of-type {
						background-position: top right;
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/production-clothes/option-4.svg');
					}
					&.option-5 span:first-of-type {
						background-position: top right;
						background-size: contain;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/production-clothes/option-5.svg');
					}
					&.option-6 span:first-of-type {
						background-position: center;
						background-size: 64% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/production-clothes/option-6.svg');
					}
					&.correct,
					&.animateCorrect {
						background-image: linear-gradient(#C2F2C2, #36DD36, #25D025);
					}
					&.wrong,
					&.animateWrong {
						background-image: linear-gradient(#FFB9C8, #F55679, #F73963);
					}
				}
			}	
		}
	}

	&.production-allergies .MultipleChoice-content {
		.MultipleChoice-options {
			width: 52em;
			margin-top: 5em;
			.MultipleChoice-optionsWrap {
				padding: 1em;				
				@include flex('space-between', 'center');
				flex-wrap: wrap;
				.MultipleChoice-option {
					width: 10.88em;
					height: 11.67em;
					background-color: white;
					border: 0.25em solid #12283E;
					border-radius: 1.45em;			
					&.option-1 span:first-of-type {
						background-size: 7.86em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/production-allergies/option-1.svg');
					}
					&.option-2 span:first-of-type {
						background-size: 8.54em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/production-allergies/option-2.svg');
					}
					&.option-3 span:first-of-type {
						background-size: 9em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/production-allergies/option-3.svg');
					}
					&.option-4 span:first-of-type {
						background-size: 10em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/production-allergies/option-4.svg');
					}
					&.correct,
					&.animateCorrect {
						background-image: linear-gradient(#C2F2C2, #36DD36, #25D025);
					}
					&.wrong,
					&.animateWrong {
						background-image: linear-gradient(#FFB9C8, #F55679, #F73963);
					}
				}
			}	
		}
	}

	&.production-allergies-2 .MultipleChoice-content {
		.MultipleChoice-options {
			width: 45em;
			margin-top: 2em;
			.MultipleChoice-optionsWrap {
				padding: 1em;				
				@include flex('center', 'center');
				flex-wrap: wrap;
				.MultipleChoice-option {
					width: 10.88em;
					height: 11.67em;
					background-color: white;
					border: 0.25em solid #12283E;
					border-radius: 1.45em;
					margin: 1em;
					margin-bottom: 0.5em;
					margin-top: 0.5em;		

					&.option-2 span:first-of-type {
						background-size: 8.54em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/production-allergies/option-2.svg');
					}
					&.option-3 span:first-of-type {
						background-size: 9em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/production-allergies/option-3.svg');
					}
					&.option-5 span:first-of-type {
						background-size: 7em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/production-allergies/option-5.svg');
					}
					&.option-6 span:first-of-type {
						background-size: 6em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/production-allergies/option-6.svg');
					}
					&.option-7 span:first-of-type {
						background-size: 4.5em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/production-allergies/option-7.svg');
					}
					&.correct,
					&.animateCorrect {
						background-image: linear-gradient(#C2F2C2, #36DD36, #25D025);
					}
					&.wrong,
					&.animateWrong {
						background-image: linear-gradient(#FFB9C8, #F55679, #F73963);
					}
				}
			}	
		}
	}

	&.repetition-1-a-lunch .MultipleChoice-content {
		
		.MultipleChoice-options {
			width: 40em;
			
			.MultipleChoice-optionsWrap {
				@include flex('center', 'center');
				flex-wrap: wrap;
				.MultipleChoice-option {
					width: 10.89em;
					height: 12.12em;
					margin: 0 0.4em 0.8em 0.4em;
					background-color: white;
					border: 0.25em solid #12283E;
					border-radius: 1.45em;
					
					span:first-of-type {
						background-position: top center;
					}
					&.option-1 span:first-of-type {
						background-position: center;
						background-size: 90% 90%;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-1-a-lunch/option-1.svg');
					}
					&.option-2 span:first-of-type {
						background-position: center;
						background-size: 90% 90%;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-1-a-lunch/option-2.svg');
					}
					&.option-3 span:first-of-type {
						background-position: center;
						background-size: 90% 90%;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-1-a-lunch/option-3.svg');
					}
					&.option-4 span:first-of-type {
						background-position: center;
						background-size: 80% 80%;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-1-a-lunch/option-4.svg');
					}
					&.option-5 span:first-of-type {
						background-position: center;
						background-size: 80% 80%;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-1-a-lunch/option-5.svg');
					}
					&.option-6 span:first-of-type {
						background-position: center;
						background-size: 60% 60%;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-1-a-lunch/option-6.svg');
					}
					&.correct,
					&.animateCorrect {
						background-image: linear-gradient(#C2F2C2, #36DD36, #25D025);
					}
					&.wrong,
					&.animateWrong {
						background-image: linear-gradient(#FFB9C8, #F55679, #F73963);
					}
				}
			}	
		}
	}
	
	&.repetition-1-a-hands .MultipleChoice-content {
		
		.MultipleChoice-options {
			width: 40em;
			
			.MultipleChoice-optionsWrap {
				position: relative;
				flex-wrap: wrap;
				width: 40em;
				height: 25.17em;
				background-color: white;
				background-size: 98% 98%;
				background-position: bottom -0.1em center;
				border: 0.25em solid #12283E;
				border-radius: 1.45em;
				background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-1-a-hands/background.svg');
					
				.MultipleChoice-option {
					border-radius: 100%;
					
					span:first-of-type {
						background-position: top center;
					}
					&.option-1 {
						position: absolute;
						bottom: 4em;
						left: calc(50% - 16.5em);
						width: 8.75em;
						height: 8.75em;
					}
					&.option-2 {
						position: absolute;
						bottom: 5.25em;
						left: calc(50% + 8em);
						width: 10.25em;
						height: 8.25em;
					}
					&.option-3 {
						-webkit-clip-path: polygon(48% 63%, 53% 55%, 49% 48%, 44% 52%, 42% 40%, 34% 24%, 38% 15%, 33% 4%, 29% 16%, 24% 0, 10% 4%, 12% 19%, 2% 18%, 2% 32%, 8% 28%, 6% 18%, 17% 19%, 16% 1%, 20% 14%, 26% 10%, 29% 16%, 35% 24%, 44% 47%, 42% 56%);
						clip-path: polygon(48% 63%, 53% 55%, 49% 48%, 44% 52%, 42% 40%, 34% 24%, 38% 15%, 33% 4%, 29% 16%, 24% 0, 10% 4%, 12% 19%, 2% 18%, 2% 32%, 8% 28%, 6% 18%, 17% 19%, 16% 1%, 20% 14%, 26% 10%, 29% 16%, 35% 24%, 44% 47%, 42% 56%);
						&.correct,
						&.animateCorrect {
							bottom: 22em;
							left: calc(50% - 12.5em);
							position: absolute;
							width: 2.5em;
							height: 2.5em;
							border-color: $white;
							border-width: 0.22em;
							border-style: solid;
							clip-path: none;
							-webkit-clip-path: none;
						}
					}
					&.option-4 {
						bottom: 0.5em;
						left: calc(50% + 11.5em);
						position: absolute;
						width: 6.25em;
						height: 3.5em;
					}
					&.option-5 {
						bottom: 13em;
						left: calc(50% - 10.5em);
						position: absolute;
						width: 2em;
						height: 2em;
					}
					&.correct,
					&.animateCorrect {
						border-color: $white;
						border-width: 0.22em;
						border-style: solid;
					}
					&.wrong,
					&.animateWrong {
						border-color: $white;
						border-width: 0.22em;
						border-style: solid;
					}
				}
			}	
		}
	}

	&.repetition-1-a-private-clothes .MultipleChoice-content {
		
		.MultipleChoice-options {
			width: 40em;
			
			.MultipleChoice-optionsWrap {
				@include flex('center', 'center');
				.MultipleChoice-option {
					width: 10.89em;
					height: 12.12em;
					margin: 0 0.4em 0.8em 0.4em;
					background-color: white;
					border: 0.25em solid #12283E;
					border-radius: 1.45em;
					
					span:first-of-type {
						background-position: top center;
					}
					&.option-1 span:first-of-type {
						background-position: center;
						background-size: 80% 80%;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-1-a-private-clothes/option-1.svg');
					}
					&.option-2 span:first-of-type {
						background-position: center;
						background-size: 90% 90%;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-1-a-private-clothes/option-2.svg');
					}
					&.option-3 span:first-of-type {
						background-position: center;
						background-size: 90% 90%;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-1-a-private-clothes/option-3.svg');
					}
					&.option-4 span:first-of-type {
						background-position: center;
						background-size: 80% 80%;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-1-a-private-clothes/option-4.svg');
					}
					&.correct,
					&.animateCorrect {
						background-image: linear-gradient(#C2F2C2, #36DD36, #25D025);
					}
					&.wrong,
					&.animateWrong {
						background-image: linear-gradient(#FFB9C8, #F55679, #F73963);
					}
				}
			}
		}
	}
	&.repetition-1-a-work-clothes .MultipleChoice-content {
		
		.MultipleChoice-options {
			width: 40em;
			
			.MultipleChoice-optionsWrap {
				@include flex('center', 'center');
				.MultipleChoice-option {
					width: 10.89em;
					height: 12.12em;
					margin: 0 0.4em 0.8em 0.4em;
					background-color: white;
					border: 0.25em solid #12283E;
					border-radius: 1.45em;
					
					span:first-of-type {
						background-position: top center;
					}
					&.option-1 span:first-of-type {
						background-position: center;
						background-size: 80% 80%;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-1-a-work-clothes/option-1.svg');
					}
					&.option-2 span:first-of-type {
						background-position: center;
						background-size: 90% 90%;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-1-a-work-clothes/option-2.svg');
					}
					&.option-3 span:first-of-type {
						background-position: center;
						background-size: 90% 90%;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-1-a-work-clothes/option-3.svg');
					}
					&.option-4 span:first-of-type {
						background-position: center;
						background-size: 80% 80%;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-1-a-work-clothes/option-4.svg');
					}
					&.correct,
					&.animateCorrect {
						background-image: linear-gradient(#C2F2C2, #36DD36, #25D025);
					}
					&.wrong,
					&.animateWrong {
						background-image: linear-gradient(#FFB9C8, #F55679, #F73963);
					}
				}
			}
		}
	}
	&.repetition-1-a-neither-clothes .MultipleChoice-content {
		
		.MultipleChoice-options {
			width: 40em;
			
			.MultipleChoice-optionsWrap {
				@include flex('center', 'center');
				.MultipleChoice-option {
					width: 10.89em;
					height: 12.12em;
					margin: 0 0.4em 0.8em 0.4em;
					background-color: white;
					border: 0.25em solid #12283E;
					border-radius: 1.45em;
					
					span:first-of-type {
						background-position: top center;
					}
					&.option-1 span:first-of-type {
						background-position: center;
						background-size: 80% 80%;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-1-a-neither-clothes/option-1.svg');
					}
					&.option-2 span:first-of-type {
						background-position: center;
						background-size: 80% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-1-a-neither-clothes/option-2.svg');
					}
					&.option-3 span:first-of-type {
						background-position: center;
						background-size: 90% 90%;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-1-a-neither-clothes/option-3.svg');
					}
					&.option-4 span:first-of-type {
						background-position: center;
						background-size: 80% 80%;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-1-a-neither-clothes/option-4.svg');
					}
					&.correct,
					&.animateCorrect {
						background-image: linear-gradient(#C2F2C2, #36DD36, #25D025);
					}
					&.wrong,
					&.animateWrong {
						background-image: linear-gradient(#FFB9C8, #F55679, #F73963);
					}
				}
			}
		}
	}
	&.repetition-1-b-filled-boxes .MultipleChoice-content {
		
		.MultipleChoice-options {
			width: 40em;
			
			.MultipleChoice-optionsWrap {
				@include flex('center', 'center');
				.MultipleChoice-option {
					width: 10.89em;
					height: 12.12em;
					margin: 0 0.4em 0.8em 0.4em;
					background-color: white;
					border: 0.25em solid #12283E;
					border-radius: 1.45em;
					
					span:first-of-type {
						background-position: top center;
					}
					&.option-1 span:first-of-type {
						background-position: center;
						background-size: 80% 80%;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-1-b-filled-boxes/option-1.svg');
					}
					&.option-2 span:first-of-type {
						background-position: center;
						background-size: 90% 90%;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-1-b-filled-boxes/option-2.svg');
					}
					&.option-3 span:first-of-type {
						background-position: center;
						background-size: 90% 90%;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-1-b-filled-boxes/option-3.svg');
					}
					&.option-4 span:first-of-type {
						background-position: center;
						background-size: 80% 80%;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-1-b-filled-boxes/option-4.svg');
					}
					&.correct,
					&.animateCorrect {
						background-image: linear-gradient(#C2F2C2, #36DD36, #25D025);
					}
					&.wrong,
					&.animateWrong {
						background-image: linear-gradient(#FFB9C8, #F55679, #F73963);
					}
				}
			}
		}
	}

	&.repetition-2-a-labcoat .MultipleChoice-content {
		.MultipleChoice-options {
			width: 40em;
			
			.MultipleChoice-optionsWrap {
				@include flex('center', 'center');
				.MultipleChoice-option {
					width: 10.89em;
					height: 12.12em;
					margin: 0 0.4em 0.8em 0.4em;
					background-color: white;
					border: 0.25em solid #12283E;
					border-radius: 1.45em;
					
					span:first-of-type {
						background-position: top center;
					}
					&.option-1 span:first-of-type {
						background-position: top -3.5em center;
						background-size: 90% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-a-labcoat/option-1.svg');
					}
					&.option-2 span:first-of-type {
						background-position: top -3.75em center;
						background-size: 100% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-a-labcoat/option-2.svg');
					}
					&.option-3 span:first-of-type {
						background-position: top -3.75em center;
						background-size: 100% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-a-labcoat/option-3.svg');
					}
					&.option-4 span:first-of-type {
						background-position: top -3.5em center;
						background-size: 90% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-a-labcoat/option-4.svg');
					}
					&.correct,
					&.animateCorrect {
						background-image: linear-gradient(#C2F2C2, #36DD36, #25D025);
					}
					&.wrong,
					&.animateWrong {
						background-image: linear-gradient(#FFB9C8, #F55679, #F73963);
					}
				}
			}
		}
	}

	&.repetition-2-a-lockers .MultipleChoice-content {
		.MultipleChoice-options {
			width: 50em;
			
			.MultipleChoice-optionsWrap {
				@include flex('center', 'center');
				flex-wrap: wrap;
				width: 50em;
				height: 25em;
				
				.MultipleChoice-option {
					width: 10.89em;
					height: 12.12em;
					margin: 0 0.4em 0.8em 0.4em;
					background-color: white;
					border: 0.25em solid #12283E;
					border-radius: 1.45em;
					
					span:first-of-type {
						background-position: top center;
					}
					&.option-1 span:first-of-type {
						background-position: top calc(50%) left calc(50%);
						background-size: 90% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-a-lockers/option-1.svg');
					}
					&.option-2 span:first-of-type {
						background-position: top calc(50%) left calc(50%);
						background-size: 80% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-a-lockers/option-2.svg');
					}
					&.option-3 span:first-of-type {
						background-position: top calc(50%) left calc(50%);
						background-size: 70% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-a-lockers/option-3.svg');
					}
					&.option-4 span:first-of-type {
						background-position: top calc(50%) left calc(50%);
						background-size: 80% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-a-lockers/option-4.svg');
					}
					&.option-5 span:first-of-type {
						background-position: top calc(50%) left calc(50%);
						background-size: 80% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-a-lockers/option-5.svg');
					}
					&.option-6 span:first-of-type {
						background-position: top calc(50%) left calc(50%);
						background-size: 80% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-a-lockers/option-6.svg');
					}
					&.option-7 span:first-of-type {
						background-position: top calc(50%) left calc(50%);
						background-size: 90% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-a-lockers/option-7.svg');
					}
					&.option-8 span:first-of-type {
						background-position: top calc(50%) left calc(50% + 0.5em);
						background-size: 70% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-a-lockers/option-8.svg');
					}
					&.correct,
					&.animateCorrect {
						background-image: linear-gradient(#C2F2C2, #36DD36, #25D025);
					}
					&.wrong,
					&.animateWrong {
						background-image: linear-gradient(#FFB9C8, #F55679, #F73963);
					}
				}
			}
		}
	}

	&.repetition-2-b-never-bring .MultipleChoice-content {
		.MultipleChoice-options {
			width: 40em;
			
			.MultipleChoice-optionsWrap {
				@include flex('center', 'center');
				.MultipleChoice-option {
					width: 10.89em;
					height: 12.16em;
					margin: 0 0.4em 0.8em 0.4em;
					background-color: white;
					border: 0.25em solid #12283E;
					border-radius: 1.45em;
					
					span:first-of-type {
						background-position: top center;
					}
					&.option-1 span:first-of-type {
						background-position: center;
						background-size: 80% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-b-never-bring/option-1.svg');
					}
					&.option-2 span:first-of-type {
						background-position: center;
						background-size: 80% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-b-never-bring/option-2.svg');
					}
					&.option-3 span:first-of-type {
						background-position: center;
						background-size: 80% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-b-never-bring/option-3.svg');
					}
					&.option-4 span:first-of-type {
						background-position: center;
						background-size: 100% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-b-never-bring/option-4.svg');
					}
					&.correct,
					&.animateCorrect {
						background-image: linear-gradient(#C2F2C2, #36DD36, #25D025);
					}
					&.wrong,
					&.animateWrong {
						background-image: linear-gradient(#FFB9C8, #F55679, #F73963);
					}
				}
			}
		}
	}

	&.repetition-2-b-contaminated-meat .MultipleChoice-content {
		.MultipleChoice-options {
			width: 40em;
			
			.MultipleChoice-optionsWrap {
				@include flex('center', 'center');
				.MultipleChoice-option {
					width: 10.89em;
					height: 12.16em;
					margin: 0 0.4em 0.8em 0.4em;
					background-color: white;
					border: 0.25em solid #12283E;
					border-radius: 1.45em;
					
					span:first-of-type {
						background-position: top center;
					}
					&.option-1 span:first-of-type {
						background-position: center;
						background-size: 80% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-b-contaminated-meat/option-1.svg');
					}
					&.option-2 span:first-of-type {
						background-position: center;
						background-size: 80% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-b-contaminated-meat/option-2.svg');
					}
					&.option-3 span:first-of-type {
						background-position: center;
						background-size: 80% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-b-contaminated-meat/option-3.svg');
					}
					&.option-4 span:first-of-type {
						background-position: center;
						background-size: 80% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-b-contaminated-meat/option-4.svg');
					}
					&.correct,
					&.animateCorrect {
						background-image: linear-gradient(#C2F2C2, #36DD36, #25D025);
					}
					&.wrong,
					&.animateWrong {
						background-image: linear-gradient(#FFB9C8, #F55679, #F73963);
					}
				}
			}
		}
	}

	&.repetition-2-b-contaminated-meat2 .MultipleChoice-content {
		.MultipleChoice-options {
			width: 40em;
			
			.MultipleChoice-optionsWrap {
				@include flex('center', 'center');
				.MultipleChoice-option {
					width: 10.89em;
					height: 12.16em;
					margin: 0 0.4em 0.8em 0.4em;
					background-color: white;
					border: 0.25em solid #12283E;
					border-radius: 1.45em;
					
					span:first-of-type {
						background-position: top center;
					}
					&.option-1 span:first-of-type {
						background-position: center;
						background-size: 80% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-b-contaminated-meat2/option-1.svg');
					}
					&.option-2 span:first-of-type {
						background-position: center;
						background-size: 80% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-b-contaminated-meat2/option-2.svg');
					}
					&.option-3 span:first-of-type {
						background-position: center;
						background-size: 80% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-b-contaminated-meat2/option-3.svg');
					}
					&.option-4 span:first-of-type {
						background-position: center;
						background-size: 80% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-b-contaminated-meat2/option-4.svg');
					}
					&.correct,
					&.animateCorrect {
						background-image: linear-gradient(#C2F2C2, #36DD36, #25D025);
					}
					&.wrong,
					&.animateWrong {
						background-image: linear-gradient(#FFB9C8, #F55679, #F73963);
					}
				}
			}
		}
	}

	&.repetition-2-b-vermin-signs .MultipleChoice-content {
		.MultipleChoice-options {
			width: 47em;
			
			.MultipleChoice-optionsWrap {
				@include flex('center', 'center');
				.MultipleChoice-option {
					height: 12.16em;
					margin: 0 0.4em 0.8em 0.4em;
					background-color: white;
					border: 0.25em solid #12283E;
					border-radius: 1.45em;
					
					span:first-of-type {
						background-position: top center;
					}
					&.option-1 span:first-of-type {
						background-position: center;
						background-size: 100% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-b-vermin-signs/option-1.svg');
					}
					&.option-2 span:first-of-type {
						background-position: center;
						background-size: 100% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-b-vermin-signs/option-2.svg');
					}
					&.option-3 span:first-of-type {
						background-position: center;
						background-size: 100% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-b-vermin-signs/option-3.svg');
					}
					&.option-4 span:first-of-type {
						background-position: center;
						background-size: 100% auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-b-vermin-signs/option-4.svg');
					}
				}
			}
		}
	}

	&.production-2-trays-carts .MultipleChoice-content {
		.MultipleChoice-intro {
			margin-bottom: 5em;
		}

		.MultipleChoice-options {
			width: 49.89em;
			.MultipleChoice-optionsWrap {
				@include flex('space-between', 'center');
				.MultipleChoice-option {
					width: 10.89em;
					height: 12.17em;
					margin: 0 0.4em 0.8em 0.4em;
					background-color: white;
					border: 0.25em solid #12283E;
					border-radius: 1.72em;
					
					&.option-1 span:first-of-type {
						background-size: 7.67em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/production-2-trays-carts/option-1.svg');
					}
					&.option-2 span:first-of-type {
						background-position: center;
						background-size: 8.33em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/production-2-trays-carts/option-2.svg');
					}
					&.option-3 span:first-of-type {
						background-position: center;
						background-size: 8.33em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/production-2-trays-carts/option-3.svg');
					}
					&.option-4 span:first-of-type {
						background-position: center;
						background-size: 7.67em auto;
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/production-2-trays-carts/option-4.svg');
					}
				}
			}
		}
	}

	&.repetition-2-1a-work-locker .MultipleChoice-content {
		.MultipleChoice-intro {
			margin-bottom: 2em;
		}
		.MultipleChoice-options {
			width: 37.33em;
			.MultipleChoice-optionsWrap {
				@include flex('center', 'center');
				flex-wrap: wrap;
				.MultipleChoice-option {
					width: 10.89em;
					height: 12.17em;
					margin: 0 0.4em 0.8em 0.4em;
					background-color: white;
					border: 0.25em solid #12283E;
					border-radius: 1.75em;
					span:first-of-type {
						background-size: 7.71em auto;
						background-position: center center;
					}
					&.option-1 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-1a-work-locker/option-1.svg');
					}
					&.option-2 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-1a-work-locker/option-2.svg');
					}
					&.option-3 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-1a-work-locker/option-3.svg');
					}	
					&.option-4 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-1a-work-locker/option-4.svg');
					}	
					&.option-5 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-1a-work-locker/option-5.svg');
					}	
					&.correct,
					&.animateCorrect {
						background-image: linear-gradient(#C2F2C2, #36DD36, #25D025);
					}
					&.wrong,
					&.animateWrong {
						background-image: linear-gradient(#FFB9C8, #F55679, #F73963);
					}
				}
			}	
		}
	}

	&.repetition-2-1a-private-locker .MultipleChoice-content {
		.MultipleChoice-intro {
			margin-bottom: 2em;
		}
		.MultipleChoice-options {
			width: 50em;
			.MultipleChoice-optionsWrap {
				@include flex('center', 'center');
				flex-wrap: wrap;
				.MultipleChoice-option {
					width: 10.89em;
					height: 12.17em;
					margin: 0 0.4em 0.8em 0.4em;
					background-color: white;
					border: 0.25em solid #12283E;
					border-radius: 1.75em;
					span:first-of-type {
						background-size: 7.71em auto;
						background-position: center center;
					}
					&.option-1 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-1a-private-locker/option-1.svg');
					}
					&.option-2 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-1a-private-locker/option-2.svg');
					}
					&.option-3 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-1a-private-locker/option-3.svg');
					}	
					&.option-4 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-1a-private-locker/option-4.svg');
					}	
					&.correct,
					&.animateCorrect {
						background-image: linear-gradient(#C2F2C2, #36DD36, #25D025);
					}
					&.wrong,
					&.animateWrong {
						background-image: linear-gradient(#FFB9C8, #F55679, #F73963);
					}
				}
			}	
		}
	}

	&.repetition-2-1b-allergens .MultipleChoice-content {
		.MultipleChoice-intro {
			margin-bottom: 2em;
		}
		.MultipleChoice-options {
			width: 38em;
			.MultipleChoice-optionsWrap {
				@include flex('center', 'center');
				flex-wrap: wrap;
				.MultipleChoice-option {
					width: 10.89em;
					height: 12.17em;
					margin: 0 0.4em 0.8em 0.4em;
					background-color: white;
					border: 0.25em solid #12283E;
					border-radius: 1.75em;
					span:first-of-type {
						background-size: 7.71em auto;
						background-position: center center;
					}
					&.option-1 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-1b-allergens/option-1.svg');
					}
					&.option-2 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-1b-allergens/option-2.svg');
					}
					&.option-3 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-1b-allergens/option-3.svg');
					}	
					&.option-4 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-1b-allergens/option-4.svg');
						background-size: 4em auto;
					}
					&.option-5 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-1b-allergens/option-5.svg');
						background-size: 7em auto;
					}	
					&.correct,
					&.animateCorrect {
						background-image: linear-gradient(#C2F2C2, #36DD36, #25D025);
					}
					&.wrong,
					&.animateWrong {
						background-image: linear-gradient(#FFB9C8, #F55679, #F73963);
					}
				}
			}	
		}
	}

	&.repetition-2-2a-lockers .MultipleChoice-content {
		.MultipleChoice-intro {
			margin-bottom: 2em;
		}
		.MultipleChoice-options {
			width: 50em;
			.MultipleChoice-optionsWrap {
				@include flex('center', 'center');
				flex-wrap: wrap;
				.MultipleChoice-option {
					width: 10em;
					height: 10em;
					margin: 0 0.4em 0.8em 0.4em;
					background-color: white;
					border: 0.25em solid #12283E;
					border-radius: 1.75em;
					span:first-of-type {
						background-size: auto 80%;
						background-position: center center;
					}
					&.option-1 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-2a-lockers/option-1.svg');
						background-size: 80% auto;
					}
					&.option-2 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-2a-lockers/option-2.svg');
						background-size: 80% auto;
					}
					&.option-3 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-2a-lockers/option-3.svg');

					}	
					&.option-4 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-2a-lockers/option-4.svg');
					}	
					&.option-5 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-2a-lockers/option-5.svg');
						background-size: 80% auto;
					}
					&.option-6 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-2a-lockers/option-6.svg');
						background-size: 80% auto;
					}
					// &.option-7 span:first-of-type {
					// 	background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-2a-lockers/option-7.svg');
					// }	
					&.option-8 span:first-of-type {
						background-image: url('../../../../../assets/images/modules/tasks/multiple-choice/repetition-2-2a-lockers/option-8.svg');
						background-size: auto 70%;
					}	
					&.correct,
					&.animateCorrect {
						background-image: linear-gradient(#C2F2C2, #36DD36, #25D025);
					}
					&.wrong,
					&.animateWrong {
						background-image: linear-gradient(#FFB9C8, #F55679, #F73963);
					}
				}
			}	
		}

	}
	 
}