@mixin gradient-frame() {
	background-image: url('../assets/images/backgrounds/frame-shade.svg');
}

@mixin background-danish-crown () {
	background-image: url('../assets/images/backgrounds/dc.svg');
}

@mixin background-sunrise-1 {
	background-image: url('../assets/images/backgrounds/dc-sunrise-1.svg');
}
@mixin background-sunrise-2 {
	background-image: url('../assets/images/backgrounds/dc-sunrise-2.svg');
}
@mixin background-sunrise-3 {
	background-image: url('../assets/images/backgrounds/dc-sunrise-3.svg');
}
@mixin background-sunrise-4 {
	background-image: url('../assets/images/backgrounds/dc-sunrise-4.svg');
}
@mixin background-sunrise-5 {
	background-image: url('../assets/images/backgrounds/dc-sunrise-5.svg');
}

@mixin background-dressing-room() {
	background-color: white;
	background-image:
		url('../assets/images/backgrounds/dressing-room-items.svg'),
		url('../assets/images/backgrounds/dressing-room-floor-grid.svg'),
		linear-gradient(#A2B5C4, #D9E6EE),
		url('../assets/images/backgrounds/slaughterhouse-wall-grid.svg'),
		radial-gradient(rgba(white, 0), rgba(#AFBFCB, .29) 10%, rgba(#ACDDFF, 0.62)),
		linear-gradient(#788999, rgba(#C5D1DB, 0.09))
		;

	background-size: 
		auto 31.93em,
		auto 10.52em,
		100% 10.52em,
		100% auto,
		122% calc(100% - 4em),
		auto 9em;
	
	background-position: 
		bottom 1.7em left calc(50% + 0.5em),
		bottom center,
		bottom center,
		center center,
		top -4em center,
		top center;
	;
	background-repeat:
		no-repeat,
		repeat-x,
		no-repeat,
		repeat-y,
		no-repeat,
		no-repeat;
}

@mixin background-dressing-room2($languageId) {
	background-color: white;
	background-image:
		url('../assets/images/backgrounds/dressing-room-items2-hangings.svg'),
		url('../assets/images/backgrounds/dressing-room-items2.svg'),
		url('../assets/images/backgrounds/dressing-room-floor-grid.svg'),
		linear-gradient(#A2B5C4, #D9E6EE),
		url('../assets/images/backgrounds/slaughterhouse-wall-grid.svg'),
		radial-gradient(rgba(white, 0), rgba(#AFBFCB, .29) 10%, rgba(#ACDDFF, 0.62)),
		linear-gradient(#788999, rgba(#C5D1DB, 0.09))
		;

	background-size: 
		auto 3em,
		auto 31.93em,
		auto 10.52em,
		100% 10.52em,
		100% auto,
		122% calc(100% - 4em),
		auto 9em;
	
	background-position: 
		top center,
		bottom 1.7em left calc(50% + 0.5em),
		bottom center,
		bottom center,
		center center,
		top -4em center,
		top center;
	;
	background-repeat:
		no-repeat,
		no-repeat,
		repeat-x,
		no-repeat,
		repeat-y,
		no-repeat,
		no-repeat;
	&::before {
		// Covers background image
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		background-image: url('../assets/images/text/private-' + $languageId + '.svg'), url('../assets/images/text/work-' + $languageId + '.svg');
		background-size: auto 0.7em, auto 0.7em;
		background-position: bottom 32.1em left calc(50% + 11.9em), bottom 32.1em left calc(50% + 19.6em);
	}
	&.da::before {
		background-size: auto 0.7em, auto 0.9em;
		background-position: bottom 32.1em left calc(50% + 11.92em), bottom 31.9em left calc(50% + 19.6em);;
	}
	&.de::before {
		background-position: bottom 32.1em left calc(50% + 11.92em), bottom 32.1em left calc(50% + 19.6em);;
	}
}

@mixin background-slaughterhouse() {
	background-color: white;
	background-image: 
		url('../assets/images/backgrounds/slaughterhouse-wall-hangings.svg'),
		url('../assets/images/backgrounds/slaughterhouse-meat.svg'),
		url('../assets/images/backgrounds/slaughterhouse-tables.svg'),
		url('../assets/images/backgrounds/slaughterhouse-floor-vents.svg'),
		url('../assets/images/backgrounds/slaughterhouse-floor-grid.svg'),
		linear-gradient(#A2B5C4, #D9E6EE),
		url('../assets/images/backgrounds/slaughterhouse-wall-grid.svg'),
		radial-gradient(rgba(white, 0), rgba(#AFBFCB, .35) 10%, rgba(#ACDDFF, 0.75));
	background-size: 
		auto 1.1em,
		auto 19.32em,
		auto 12.5em,
		auto 5.14em,
		auto 9.67em,
		100% 9.67em,
		100% auto,
		122% calc(100% - 4em);
	
	background-position: 
		top 4.9em center,
		top 4.89em left calc(50% + 4em),
		bottom 2.6em center,
		bottom center,
		bottom center,
		bottom center,
		center center,
		top -4em center;
	;
	background-repeat:
		repeat-x,
		no-repeat,
		no-repeat,
		no-repeat,
		repeat-x,
		no-repeat,
		repeat-y,
		no-repeat;
}

@mixin background-hygiene-lock() {
	background-color: white;
	background-image: 
		url('../assets/images/backgrounds/hygiene-lock.svg'),
		linear-gradient(#D0A054, #D0A054),
		url('../assets/images/backgrounds/dressing-room-floor-grid.svg'),
		linear-gradient(#D8AC65, #F2CA93),
		url('../assets/images/backgrounds/slaughterhouse-wall-grid.svg'),
		radial-gradient(rgba(white, 0), rgba(#AFBFCB, .35) 10%, rgba(#ACDDFF, 0.75));
	background-size: 
		auto 27.84em,
		100% 0.67em,
		auto 9.67em,
		100% 9.67em,
		100% auto,
		122% calc(100% - 4em);
	
	background-position: 
		bottom 1.5em center,
		bottom 9.65em center,
		bottom center,
		bottom center,
		center center,
		top -4em center;
	;
	background-repeat:
		no-repeat,
		no-repeat,
		repeat-x,
		no-repeat,
		repeat-y,
		no-repeat;
}

@mixin background-production-room-a() {
	background-color: white;
	background-image:
		radial-gradient(rgba(#ACDDFF, 0), rgba(#ACDDFF, .45)),
		url('../assets/images/backgrounds/production-room-a.svg'),
		url('../assets/images/backgrounds/production-floor-pattern.svg'),
		linear-gradient(to bottom, rgba(#2A2926, 1), rgba(#5C5753, 0.94)),
		linear-gradient(135deg, 
			#EFEFEF, 
			#E8E7E7, 
			#C1C1C1, 
			#F5F5F5,
			#E8E4E4,
			#D3D3D3,
			#FFFFFF,
			#BFBFBF
		);
	background-size:
		100% 100%,
		auto 33.77em,
		auto 14.2em,
		auto 14.2em,
		auto 17em;
	
	background-position:
		top center,
		bottom 2em left calc(50% + 3.75em),
		bottom center,
		bottom center,
		bottom 14em center;

	background-repeat:
		no-repeat,
		no-repeat,
		repeat-x,
		no-repeat,
		no-repeat;
}

@mixin background-production-room-b() {
	background-color: white;
	background-image:
		radial-gradient(rgba(#ACDDFF, 0), rgba(#ACDDFF, .45)),
		url('../assets/images/backgrounds/production-room-b.svg'),
		url('../assets/images/backgrounds/production-floor-pattern.svg'),
		linear-gradient(to bottom, rgba(#2A2926, 1), rgba(#5C5753, 0.94)),
		linear-gradient(135deg, 
			#EFEFEF, 
			#E8E7E7, 
			#C1C1C1, 
			#F5F5F5,
			#E8E4E4,
			#D3D3D3,
			#FFFFFF,
			#BFBFBF
		);
	background-size:
		100% 100%,
		auto 42em,
		auto 14.2em,
		auto 14.2em,
		auto 17em;
	
	background-position:
		top center,
		bottom 1.5em center,
		bottom center,
		bottom center,
		bottom 14em center;

	background-repeat:
		no-repeat,
		no-repeat,
		repeat-x,
		no-repeat,
		no-repeat;
}