@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/backgrounds';
@import '../../../styles/animations';
.Module {
	position: relative;
	height: 100%;
	overflow: auto;
	background-color: gainsboro;
	@include hide-scrollbar();
	.Module-header {
		position: absolute;
		top: 0;
		width: 100%;
		padding-top: 1em;
		z-index: 11;
		pointer-events: none;
		@include flex('center', 'flex-start');
	
		.Module-homeBtn {
			position: absolute;
			width: 3.5em;
			height: 3.5em;
			top: 0;
			background-size: 1.89em auto;
			background-position: center center;
			pointer-events: all;
			cursor: pointer;
			
		}
		.Module-homeBtn {
			left: 0;
			background-image: url('../../../assets/images/icons/icon-home.svg');
		}
		.Module-progressBar {
			width: 37em;
		}
	}
	.Module-nextBtn {
		position: absolute;
		right: 0em;
		bottom: 0em;
		z-index: 11;

		&.done,
		&.finish {
			right: 1.5em;
			bottom: 1.5em;
			width: 7em;
		}
		&.finish {
			width: 8.77em;
		}
	}

	&.white {
		background-color: white;
	}

	&.danish-crown {
		@include background-danish-crown();
	}
	&.sunrise-1 {
		@include background-sunrise-1();
	}
	&.sunrise-2 {
		@include background-sunrise-2();
	}
	&.sunrise-3 {
		@include background-sunrise-3();
	}
	&.sunrise-4 {
		@include background-sunrise-4();
	}
	&.sunrise-5 {
		@include background-sunrise-5();
	}

	&.dressing-room {
		@include background-dressing-room();
	}
	&.dressing-room2 {
		@include background-dressing-room2('da');
		&.en{@include background-dressing-room2('en');}
		&.pl{@include background-dressing-room2('pl');}
		&.de{@include background-dressing-room2('de');}
	}
	&.slaughterhouse {
		@include background-slaughterhouse();
	}
	&.hygiene-lock {
		@include background-hygiene-lock();
	}

	&.production-room-a {
		@include background-production-room-a();
	}
	&.production-room-b {
		@include background-production-room-b();
	}
}