@import '../../../../../styles/variables';
@import '../../../../../styles/fonts';
@import '../../../../../styles/mixins';
@import '../../../../../styles/buttons';
@import '../../../../../styles/backgrounds';
@import '../../../../../styles/animations';
.Instructions {
	position: relative;
	height: 100%;
	overflow-x: hidden;

	.Instructions-content {
		position: relative;
		&.info {
			width: 17.25em;
			margin: auto;
			color: white;
		}
		&.speech {
			position: absolute;
			top: 7em;
			left: calc(50% - 12em);
			width: 32.5em;
			height: auto;
			border: 2em solid transparent;
			border-bottom-width: 4em;
			border-image-source: url('../../../../../assets/images/speech-bubble-instructions.svg');
			border-image-slice: 36 36 72 36 fill;
			border-image-width: 2em 2em 4em 2em;
			padding: 0.75em 4em 0.75em 0.75em;
			@include origin(50%, 50%);
			@include fadeInSpeechBubble();
			&.fade {
				@include fadeOutSpeechBubble();
			}
		}

		.Instructions-title {
			font-weight: 700;
			font-size: 1.5em;
			margin-bottom: 0.75em;
		}
		
		.Instructions-text {
			text-align: left;
			color: #12283E;
			p {
				font-size: 1.25em;
				font-weight: bold;
				line-height: 1.3;
				margin-bottom: 0;
				&:first-of-type {margin-top: 0;}
			}
		}
		.Instructions-audio {
			position: absolute;
			top: calc(50% - 1.36em);
			right: 0.5em;
		}
	}
	
	.Instructions-character {
		position: absolute;
		bottom: 0;
		left: 50%;

		&.manager-1 {
			left: calc(50% - 25.5em);
		}
	}
}