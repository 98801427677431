@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/backgrounds';
@import '../../../styles/animations';
.Character {
	overflow-y: hidden;

	.Character-manager {
		&.flipped {
			@include scaleX(-1);
		}

		&.manager-1 {
			position: relative;
			top: 14em;
			width: 14em;
			height: 46.9em;

			&.blinking {
				#eyesClosed {
					display: block;
				}
				#eyesOpen {
					display: none;
				}
			}

			#eyesClosed {
				display: none;
			}
		}
	}
}