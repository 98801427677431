// APPEARANCE
@mixin appearance($value) {
	-webkit-appearance: #{$value};
	-moz-appearance: #{$value};
	appearance: #{$value};
}

// TOUCH ACTION
@mixin touchaction($value) {
	touch-action: #{$value};
	-ms-touch-action: #{$value};
}

/* Autofill state for inputs */
@mixin autofilled($color, $bgColor) {
	-webkit-animation-name: autofill;
	-webkit-animation-fill-mode: both;
	animation-name: autofill;
	animation-fill-mode: both;
	@keyframes autofill {
		to {
			color: $color;
			background-color: $bgColor;
		}
	}
}

// FLEX
@mixin flex($justify: '', $align: '', $direction: '') {
	display: -webkit-box; // OLD - iOS 6-, Safari 3.1-6
	display: -moz-box; // OLD - Firefox 19- (buggy but mostly works)
	display: -ms-flexbox; // TWEENER - IE 10
	display: -webkit-flex; // NEW - Chrome
	display: flex; // NEW, Spec - Opera 12.1, Firefox 20+
	@include flex-properties($justify, $align, $direction);
}

@mixin inline-flex($justify: '', $align: '', $direction: '') {
	display: -webkit-inline-box;
	display: -moz-inline-box;
	display: -ms-inline-flexbox;
	display: -webkit-inline-flex;
	display: inline-flex;
	@include flex-properties($justify, $align, $direction);
}

@mixin flex-properties($justify, $align, $direction) {
	@if $justify != '' {
		-webkit-justify-content: #{$justify};
		justify-content: #{$justify};
		-webkit-box-pack: #{$justify};
		-ms-flex-pack: #{$justify};
	}
	@if $align != '' {
		-webkit-box-align: #{$align};
		-webkit-flex-align: #{$align};
		-ms-flex-align: #{$align};
		-webkit-align-items: #{$align};
		align-items: #{$align};
	}
	@if $direction != '' {
		-webkit-box-direction: #{$direction};
		-webkit-flex-direction: #{$direction};
		flex-direction: #{$direction};
	}
}

@mixin flex-wrap($wrap) {
	-webkit-flex-wrap: $wrap; /* Safari 6.1+ */
	flex-wrap: $wrap;
}

@mixin align-content($align) {
	-webkit-align-content: $align;
	align-content: $align;
}

@mixin align-self($value) {
	-webkit-align-self: #{$value};
	align-self: #{$value};
}

// SCALE
@mixin scale($scale) {
	-ms-transform: scale($scale); /* IE 9 */
	-webkit-transform: scale($scale); /* Safari */
	transform: scale($scale);
}

@mixin scaleX($scale) {
	-ms-transform: scaleX($scale); /* IE 9 */
	-webkit-transform: scaleX($scale); /* Safari */
	transform: scaleX($scale);
}

// ROTATE
@mixin rotate($angle) {
	-ms-transform: rotate($angle); /* IE 9 */
	-webkit-transform: rotate($angle); /* Safari */
	transform: rotate($angle);
}

// TRANSITION
@mixin transition($target, $seconds, $type) {
	-webkit-transition: #{$target} $seconds #{$type}; /* WebKit */
	-moz-transition: #{$target} $seconds #{$type}; /* Firefox */
	-o-transition: #{$target} $seconds #{$type}; /* Opera */
	-ms-transition: #{$target} $seconds #{$type}; /* MS */
	transition: #{$target} $seconds #{$type}; /* Standard */
}

@mixin transitionTransform($seconds, $type, $delay: 0) {
	-webkit-transition: -webkit-transform $seconds #{$type} $delay; /* WebKit */
	-moz-transition: -moz-transform $seconds #{$type} $delay; /* Firefox */
	-o-transition: -o-transform $seconds #{$type} $delay; /* Opera */
	-ms-transition: -ms-transform $seconds #{$type} $delay; /* MS */
	transition: transform $seconds #{$type} $delay; /* Standard */
}


@mixin transition-delay($time) {
	-webkit-transition-delay: $time;
	-moz-transition-delay: $time;
	-o-transition-delay: $time;
	transition-delay: $time;
}

@mixin animationTimingFunction($type) {
	-webkit-animation-timing-function: #{$type};
	animation-timing-function: #{$type};
}

@mixin translate($x, $y: 0) {
	-webkit-transform: translate($x, $y);
	-moz-transform: translate($x, $y);
	-ms-transform: translate($x, $y);
	-o-transform: translate($x, $y);
	transform: translate($x, $y);
}

@mixin translate3d($x: 0, $y: 0, $z: 0) {
	-ms-transform: translate3d($x, $y, $z);
	-webkit-transform: translate3d($x, $y, $z);
	transform: translate3d($x, $y, $z);
}

@mixin translateAndRotate($x: 0, $y: 0, $z: 0, $angle: 0) {
	-ms-transform: translate3d($x, $y, $z) rotate($angle);
	-webkit-transform: translate3d($x, $y, $z) rotate($angle);
	transform: translate3d($x, $y, $z) rotate($angle);
}

@mixin origin($x, $y) {
	-webkit-transform-origin: $x $y;
	transform-origin: $x $y;
}

// OPACITY
@mixin opacity($opacity) {
	$procentOpacity: $opacity * 100;
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity='
		$procentOpacity ')'; /* IE 8 */
	filter: alpha(opacity=$procentOpacity); /* IE 5-7 */
	-moz-opacity: $opacity; /* Netscape */
	-khtml-opacity: $opacity; /* Safari 1.x */
	opacity: $opacity;
}

// INPUT
@mixin input-placeholder($color) {
	input::-webkit-input-placeholder {
		color: $color !important;
	} /* Chrome/Opera/Safari */
	input:-moz-placeholder {
		color: $color !important;
	} /* Firefox 18- */
	input::-moz-placeholder {
		color: $color !important;
	} /* Firefox 19+ */
	input:-ms-input-placeholder {
		color: $color !important;
	} /* IE 10+ */
}

// TEXTAREA
@mixin textarea-placeholder($color) {
	textarea::-webkit-input-placeholder {
		color: $color !important;
	} /* Chrome/Opera/Safari */
	textarea:-moz-placeholder {
		color: $color !important;
	} /* Firefox 18- */
	textarea::-moz-placeholder {
		color: $color !important;
	} /* Firefox 19+ */
	textarea:-ms-input-placeholder {
		color: $color !important;
	} /* IE 10+ */
}

// NO-SELECT
@mixin no-select() {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently*/
}

// SCROLLBARS
/* HIde scrollbar */
@mixin hide-scrollbar() {
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		display: none;
	}
}

// CUSTOM SCROLLBAR
@mixin custom-scrollbar($width, $backgroundColor, $thumbColor) {
	/*Chrome, Safari and Edge*/
	&::-webkit-scrollbar {

		width: $width; /* width of the veritcal scrollbar */
		height: $width; /* width of the horizontal scrollbar */
	}
	&::-webkit-scrollbar-track {
		border-radius: $width;
		background-color: $backgroundColor;
	}
	&::-webkit-scrollbar-thumb {
		background-color: $thumbColor;
		border-radius: $width;
		border: 1px solid $backgroundColor;
	}
	&::-webkit-scrollbar-corner {
		background-color: rgba(0, 0, 0, 0);
	}
	/*Firefox*/
	scrollbar-width: thin;
	scrollbar-color: $thumbColor $backgroundColor;
}

@mixin rotationStyle($style) {
	-webkit-transform-style: #{$style};
	-moz-transform-style: #{$style};
	transform-style: #{$style};
}

@mixin text-shadow($hoffset, $voffset, $blur, $color) {
	-webkit-text-shadow: $hoffset $voffset $blur $color;
	text-shadow: $hoffset $voffset $blur $color;
}

// BOX SHADOW
@mixin no-box-shadow() {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
@mixin box-shadow($hoffset, $voffset, $blur, $spread, $color) {
	-webkit-box-shadow: $hoffset $voffset $blur $spread $color;
	-moz-box-shadow: $hoffset $voffset $blur $spread $color;
	box-shadow: $hoffset $voffset $blur $spread $color;
}

@mixin box-shadow-inset($hoffset, $voffset, $blur, $spread, $color) {
	-webkit-box-shadow: $hoffset $voffset $blur $spread $color inset;
	-moz-box-shadow: $hoffset $voffset $blur $spread $color inset;
	box-shadow: $hoffset $voffset $blur $spread $color inset;
}

@mixin box-shadow-inset-outset(
	$hoffset1, $voffset1, $blur1, $spread1, $color1,
	$hoffset2, $voffset2, $blur2, $spread2, $color2,
) {
	-webkit-box-shadow: $hoffset1 $voffset1 $blur1 $spread1 $color1 inset, 
		$hoffset2 $voffset2 $blur2 $spread2 $color2;
	-moz-box-shadow: $hoffset1 $voffset1 $blur1 $spread1 $color1 inset, 
		$hoffset2 $voffset2 $blur2 $spread2 $color2;
	box-shadow: $hoffset1 $voffset1 $blur1 $spread1 $color1 inset, 
		$hoffset2 $voffset2 $blur2 $spread2 $color2;
}


@mixin blurBackground($blurPixels) {
	-webkit-backdrop-filter: blur($blurPixels);
	backdrop-filter: blur($blurPixels);
}

// ANIMATIONS
@mixin blink() {
	animation: blink 0.5s ease 0s 3;
	-webkit-animation: blink 0.5s ease 0s 3;
	-moz-animation: blink 0.5s ease 0s 3;
	-o-animation: blink 0.5s ease 0s 3;
}

@keyframes blink {
	50% {
		@include scale(1.15);
		@include opacity(0);
	}
}
@-webkit-keyframes blink {
	50% {
		@include scale(1.15);
		@include opacity(0);
	}
}

@mixin fadeInOut() {
	visibility: visible;
	animation: fadeInOut 1.75s ease 0s;
	-webkit-animation: fadeInOut 1.75s ease 0s;
	-moz-animation: fadeInOut 1.75s ease 0s;
	-o-animation: fadeInOut 1.75s ease 0s;
}

@keyframes fadeInOut {
	50% {
		@include opacity(1);
	}
}
@-webkit-keyframes fadeInOut {
	50% {
		@include opacity(1);
	}
}

@mixin fadeIn($duration: 0.75s) {
	visibility: visible;
	animation: fadeIn $duration ease 0s forwards;
	-webkit-animation: fadeIn $duration ease 0s forwards;
	-moz-animation: fadeIn $duration ease 0s forwards;
	-o-animation: fadeIn $duration ease 0s forwards;
}

@keyframes fadeIn {
	50% {@include opacity(1);}
	100% {@include opacity(1);}
}
@-webkit-keyframes fadeIn {
	50% {@include opacity(1);}
	100% {@include opacity(1);}
}


@mixin fadeOutClose($duration: 1s) {
	animation: fadeOutClose $duration ease 0s forwards;
	-webkit-animation: fadeOutClose $duration ease 0s forwards;
	-moz-animation: fadeOutClose $duration ease 0s forwards;
	-o-animation: fadeOutClose $duration ease 0s forwards;
}

@keyframes fadeOutClose {
	50% {@include opacity(0); @include scale(1)}
	51% {@include opacity(0); @include scale(0)}
	100% {@include opacity(0); @include scale(0)}
}
@-webkit-keyframes fadeOutClose {
	50% {@include opacity(0); @include scale(1)}
	51% {@include opacity(0); @include scale(0)}
	100% {@include opacity(0); @include scale(0)}
}