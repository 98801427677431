@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/backgrounds';
@import '../../../styles/animations';
.Button {
	position: relative;
	width: 100%;
	height: 2.25em;
	border-radius: 0.55em;
	font-weight: bold;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	@include no-select();
	@include opacity(1);
	@include flex('center', 'center');
	span {
		display: inline-block;
	}
	&.disabled,
	&.locked {
		opacity: 0.4;
		cursor: not-allowed;		
		&:hover,
		&:focus,
		&:active {
			opacity: 0.4;
		}
	}
	&.loading {
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			pointer-events: none;
			background-image: url('../../../assets/images/icons/icon-loading.svg');
			background-size: auto 80%;
			background-position: center right;
		}	
	}

	&.default {
		background-color: white;
		color: black;
		padding: 0.5em;
		&:hover,
		&:focus,
		&:active {
			background-color: darken(white, 5%);
		}
	}

	&.cookies-popup {
		@include button-green();
		width: 10em;
		&.reject {
			@include button-red();
		}
	}

	&.login,
	&.loginFacilitator {
		height: 3.72em;
		background-image: linear-gradient(#F4DF7D 85%, #F3CD46, #E0AB21);
		border-radius: 1.89em;
		span {
			font-size: 1.94em;
			font-weight: bold;
			color: #12283E;
			text-transform: uppercase;
			@include text-shadow(0.15em, 0.1em, 0.1em, rgba(black, 0.17));
		}
		&:hover,
		&:focus,
		&:active {
			background-image: linear-gradient(darken(#F4DF7D, 10%) 85%, darken(#F3CD46, 10%), darken(#E0AB21, 10%));
		}

		&.submit {
			border: none;
			font-size: 1em;
		}
	}

	&.loginFacilitator {
		height: 2.5em;
		background-image: none;
		background-color: #f2482b;
		span {
			color: white;
			font-size: 1.5em;
		}
		&:hover,
		&:focus,
		&:active {
			background-image: none;
			background-color: darken(#f2482b, 3%);
		}
		&.loading {
			&::after {
				background-image: url('../../../assets/images/icons/icon-loading-white.svg');
			}	
		}
	}

	&.logout {
		width: 2.5em;
		height: auto;
		padding-top: 2em;
		border-radius: 0;
		background-image: url('../../../assets/images/icons/icon-logout.svg');
		background-size: 2em auto;
		background-position: top right;
		@include flex('flex-end', 'center');
		span {
			color: white;
			font-size: 0.6em;
		}
	}

	&.logout-player {
		height: 2.72em;
		width: 8em;
		@include button-yellow();
		border-radius: 1.78em;
	}

	&.switch-language {
		width: 3.25em;
		height: 2.9em;
		background-size: contain;
		background-image: url('../../../assets/images/icons/icon-language.svg');
	}

	&.facilitator {
		width: 100%;
		height: 100%;
		background-color: #f2482b;
		padding: 0.5em;
		span {
			color: white;
			font-size: 1.11em;
			font-weight: bold;
		}
		&:hover,
		&:focus,
		&:active {
			background-color: darken(#f2482b, 5%);
		}
		&.loading {
			&::after {
				background-image: url('../../../assets/images/icons/icon-loading-white.svg');
			}	
		}
	}

	&.next {
		width: 4.25em;
		height: 4.25em;
		background-image: url('../../../assets/images/icons/icon-next.svg');
		background-size: 4.25em auto;
		background-position: top left;
		margin-bottom: 1.25em;
		margin-right: 1.25em;
		&.delay {
			@include delayButton();
		}
		&.animate {
			@include opacity(0);
			animation: fadeIn 0.25s ease 0.5s forwards, bounce 0.75s cubic-bezier(0.280, 0.840, 0.420, 1) 0.75s 1 forwards;
			-webkit-animation: fadeIn 0.25s ease 0.5s forwards, bounce 0.75s cubic-bezier(0.280, 0.840, 0.420, 1) 0.75s 1 forwards;
		}
	}

	&.confirmTask {
		height: 2.67em;
		border-radius: 1.75em;
		@include button-yellow();
		span {
			font-size: 1.22em;
			font-weight: bold;
			text-transform: uppercase;
		}
	}

	&.togglePlayerStatus {
		height: 1.5em;
		padding: 0.25em 0.5em;
		span {
			font-size: 0.8em;
		}
	}

	&.small {
		left: calc(50% - 1.75em);
		width: 3.5em;
		height: 2em;
		span {
			font-size: 1.2em;
		}
	}

	&.blue {
		color: white;
		background-image: linear-gradient(#ACDDFF, #2CA7FD, #0096FF);
		&:hover,
		&:focus,
		&:active {
			background-image: linear-gradient(darken(#ACDDFF, 3%), darken(#2CA7FD, 3%), darken(#0096FF, 3%));
		}
	}

	&.yellow {
		color: #12283E;
		background-image: linear-gradient(#F8D949, #ECBD41);
		&:hover,
		&:focus,
		&:active {
			background-image: linear-gradient(darken(#F8D949, 3%), darken(#ECBD41, 3%));
		}
	}

	&.green {
		color: white;
		background-image: linear-gradient(#C2F2C2, #36DD36, #25D025);		
		&:hover,
		&:focus,
		&:active {
			background-image: linear-gradient(darken(#C2F2C2, 3%), darken(#36DD36, 3%), darken(#25D025, 3%));
		}
	}

	&.red {
		color: white;
		background-image: linear-gradient(#FFB9C8, #F55679, #F73963);
		&:hover,
		&:focus,
		&:active {
			background-image: linear-gradient(darken(#FFB9C8, 3%), darken(#F55679, 3%), darken(#F73963, 3%));
		}
	}

	&.bold {
		span {
			font-weight: bold;
		}
	}

	&.default-case {
		span {
			text-transform: none;
		}
	}
}