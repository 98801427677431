@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/backgrounds';
@import '../../../styles/animations';
.ProgressBar {
	position: relative;
	width: 100%;
	height: 3em;
	@include flex('center', 'center');
	.ProgressBar-points {
		position: relative;
		width: 100%;
		height: 1.75em;
		background-color: white;
		padding: 0.125em;
		border-radius: 1.33em;
		overflow: hidden;
		@include box-shadow-inset(0.11em, 0.11em, 0.33em, 0, rgba(#12283E, 0.35));
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 0.125em solid white;
			border-radius: 1.33em;
		}
		.ProgressBar-progress {
			width: 0;
			height: 100%;
			border-radius: 1.33em;
			background-color: #F3CD46;
			background-image: linear-gradient(#F4DF7D, #F3CD46, #E0AB21);
			background-size: 100% 100%;
			@include transition(0.5s, width, ease-in-out);
			@include box-shadow(0.11em, 0, 0, 0, rgba(black, 0.04));
		}
	}

	.ProgressBar-stars {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		.ProgressBar-star {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			@include translate(-0.75em);
		}
	}
}