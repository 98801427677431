@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/buttons';
@import '../../styles/backgrounds';
@import '../../styles/animations';
.ResetPassword {
	position: absolute;
	bottom: 8.5em;
	left: calc(50% - 10em);
	width: 20em;
	// margin: 11em auto 0 auto;
	.ResetPassword-title {
		width: 100%;
		height: 3em;
		@include flex('flex-start', 'center');
		padding: 0 1.5em;
		background-color: #f2482b;
		border-top-left-radius: 0.5em;
		border-top-right-radius: 0.5em;
		span {
			font-size: 1.2em;
			font-weight: bold;
			color: white;
			text-transform: uppercase;
			white-space: nowrap;
		}
	}
	.ResetPassword-body {
		width: 100%;
		height: calc(100% - 5em);
		background-color: white;
		border-bottom-left-radius: 0.5em;
		border-bottom-right-radius: 0.5em;
		padding: 1em 1.5em;
		.ResetPassword-form {
			position: relative;
			input {
				display: block;
				font-size: 1.11em;
				line-height: 1;
				width: 100%;
				height: 2.5em;
				border-radius: 0.25em;
				border: 1px solid #f2482b;
				margin: auto;
				margin-bottom: 1em;
				background-color: $white;
				color: #275150;
				font-weight: normal;
				text-align: center;
				@include box-shadow-inset(0, 0.15em, 0.3em, 0, rgba(#000000, 0.16));
				&::placeholder {
					color: rgba(#275150, 0.25);
				}
			}

			.ResetPassword-resetPasswordBtn {
				width: 100%;
			}
	
			
			.ResetPassword-errorMessage {
				height: 1.5em;
				margin-bottom: 0.6em;
				padding: 0 1em;
				text-align: center;
				span {
					color: red;
					font-size: 0.89;
				}
			}
		}	

		.ResetPassword-info {
			margin-bottom: 1.5em;
			span {
				font-size: 1.1em;
				color: #292929;
			}
		}
		.ResetPassword-resetPasswordBtn {
			cursor: pointer;
			span {
				color: $text-dark;
				font-size: 0.89em;
			}
			&:hover {
				text-decoration: underline;
			}
		}
	}
	
}