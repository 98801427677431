@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/buttons';
@import '../../../styles/backgrounds';
@import '../../../styles/animations';
.AdminMovePlayersPopup {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#092335, 0.6);
	z-index: 13;
	@include flex('center', 'center');

	.AdminMovePlayersPopup-content {
		position: relative;
		width: 35em;
		min-height: 20em;
		max-height: 40em;
		overflow: auto;
		color: #11466B;
		background-color: white;
		border-radius: 1em;
		@include box-shadow(0, 0, 0.66em, 0, rgba(#000000, 0.2));

		.AdminMovePlayersPopup-title {
			background-color: #f2482b;
			border-top-left-radius: 1em;
			border-top-right-radius: 1em;
			height: 3em;
			padding: 0 1em;
			text-align: center;
			@include flex('flex-start', 'center');
			span {
				color: white;
				font-size: 1.56em;
				font-weight: 700;
	
			}
		}

		.AdminMovePlayersPopup-selectWrapper {
			padding: 1em;
			text-align: left;
			.AdminMovePlayersPopup-selectLabel {
				margin-bottom: 1em;
			}
			.AdminMovePlayersPopup-select {
				width: 15em;
				border: 1px solid #f2482b;
				border-radius: 0.56em;
			}
		}

		.AdminMovePlayersPopup-players {
			padding: 1em;
			text-align: left;

			.AdminMovePlayersPopup-player {
				margin-bottom: 0.5em;
				height: 2.5em;
				padding: 0 0.5em;
				@include flex('space-between', 'center');
				&:nth-child(odd) {
					background-color: rgba(#f2482b, 0.2);
				}
				.AdminMovePlayersPopup-playerId {
					width: 10em;
				}
				.AdminMovePlayersPopup-toGameSelect {
					width: 15em;
					border: 1px solid #f2482b;
					border-radius: 0.56em;
				}
				&.header {
					text-align: left;
					background-color: #f2482b;
					height: 2em;
					span {
						color: white;
						font-weight: bold;
					}
				}
			}
		}

		.AdminMovePlayersPopup-feedback {
			padding: 1em;
			text-align: left;
			margin-bottom: 0.5em;
			span {
				display: block;
				color: black;
			}
			&.error span {color: #C13B3B; margin-bottom: 1em;}
		
		}

		.AdminMovePlayersPopup-moveBtn {
			width: 10em;
			margin: 1em auto;
		}
	}
}
