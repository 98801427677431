@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/buttons';
@import '../../styles/backgrounds';
@import '../../styles/animations';

.Login {
	height: 100%;
	width: 100%;
	@include flex('center', 'center');
	.Login-logoCGL {
		position: absolute;
		top: 1em;
		right: 0.5em;
		width: 6.11em;
		height: 2em;
	}
	.Login-logoAndTitle {
		position: absolute;
		top: 3em;
		.Login-logo {
			width: 17em;
			height: 6.2em;
			margin: 0 auto 2em auto;
		}

		.Login-title {
			display: inline-block;
			@include text-shadow(0.11em, 0.11em, 0.11em, #12283E);
			span {
				color: #ffffff;
				font-size: 3.89em;
				font-weight: bold;
				
			}
		}
	}
}